<template>
  <div>
    <v-timeline align-top dense>
      <v-virtual-scroll :items="deviceLogs" item-height="72" height="510">
        <template v-slot:default="{ item, index }">
          <v-timeline-item :key="index" color="primary" small>
            <v-container class="mx-n3">
              <v-row>
                <v-col cols="2">
                  <strong>{{ item.created_at | moment($store.getters.dateFormat) }}</strong>
                </v-col>
                <v-col cols="2">
                  <strong>{{ item.created_at | moment($store.getters.timeFormat) }}</strong>
                </v-col>
                <v-col>
                  <strong>{{ item.note }}</strong>
                  <div class="text-caption">by {{ item.user_name }}</div>
                </v-col>
              </v-row>
            </v-container>
          </v-timeline-item>
        </template>
      </v-virtual-scroll>
    </v-timeline>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'Logs',
    data: () => ({
      search: '',
      loading: true,
      users: [],
    }),
    props: ['view'],
    computed: {
      ...mapGetters(['site', 'device', 'sitePermission', 'deviceLogs']),
    },

    created () {
      this.initialize()
    },

    methods: {
      async initialize () {
        let response = await this.$http.get('/global/user_list')
        this.users = response.data.users
        this.loading = false
      },
    },
  }
</script>