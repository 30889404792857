<template>
  <v-app>
    <Header />
    <Sidebar v-if="!isMobile" />
    <v-main :class="isMobile ? 'mobile-content' : 'content'">
      <v-fade-transition mode="out-in">
        <router-view />
      </v-fade-transition>
    </v-main>
    <v-dialog v-if="!isMobile" v-model="tenantUserCardDialog" min-width="70%" transition="dialog-bottom-transition">
      <user-card/>
    </v-dialog>
  </v-app>
</template>

<script>
  import {mapGetters} from 'vuex'
  import Header from '@/components/Header/Header';
  import Sidebar from '@/components/Sidebar/Sidebar';
  import UserCard from '@/components/Tenant/UserCard.vue'
  import './Layout.scss';

  export default {
    name: 'Layout',
    components: { Header, Sidebar, UserCard },
    computed: {
      ...mapGetters(['tenantUserCardDialog', 'isMobile']),
    },
  }
</script>

<style src="./Layout.scss" lang="scss" />
