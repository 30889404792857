<template>
  <v-container fluid class="devices-page">
    <v-row class="mt-3">
      <v-col cols="12">
        <v-toolbar flat height="90" :class="isMobile ? 'fixed-toolbar-mobile' : 'fixed-toolbar'">
          <device-layout-toggle v-if="!isMobile"/>
          <device-vis-menu v-if="!isMobile"/>
          <v-spacer v-if="!isMobile"></v-spacer>
          <v-text-field
            v-model="search"
            clearable
            flat
            color="primary"
            hide-details
            prepend-inner-icon="mdi-magnify"
            label="Search"
            class="mr-5"
            @input="searchDevices"
            @click:clear="() => { search = ''; searchDevices() }"
          ></v-text-field>
          <device-filter/>
          <v-toolbar-title v-if="isMobile" class="ml-5 subtitle-1">{{filteredDevices.length}} of {{deviceTotal}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-title v-if="!isMobile" class="mr-5 subtitle-1">Devices : {{filteredDevices.length}} of {{deviceTotal}}</v-toolbar-title>
          <v-btn
            v-if="sitePermission>1 && isMobile"
            color="primary"
            icon
            dark
            @click="newDevice"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
          <v-btn
            v-if="sitePermission>1 && !isMobile"
            color="primary"
            dark
            class="mb-2"
            @click="newDevice"
          >
            Add Product
          </v-btn>
          <device-bulk-actions v-if="sitePermission>1 && !isMobile" :devices="selectedDevices"/>
        </v-toolbar>
        <div class="scrollable-content">
          <v-fade-transition mode="out-in">
            <div v-if="devicesLayout === 'table' && !isMobile">
              <v-data-table
                ref="deviceTable"
                v-model="selectedDevices"
                :headers="headers"
                :items="filteredDevices"
                :item-class="textColor"
                class="elevation-3"
                item-key="id"
                disable-pagination
                show-select
                fixed-header
                height="78vh"
                hide-default-footer
                checkbox-color="primary"
                @click:row="showDevice"
              >
                <template v-slot:[`item.image`]="{ item }">
                  <v-img v-if="item.image && item.image.length > 0" :src="item.image" alt="Wikibuild Device Picture" max-height="100px" max-width="100px" contain></v-img>
                  <v-img v-else src="https://wtec-uploads.s3.eu-central-1.amazonaws.com/assets/placeholder-image.png" :alt="item.name" max-height="100px" max-width="100px" contain></v-img>
                </template>
                <template v-slot:[`item.status`]="{ item }">
                  <v-chip v-if="item.status" :color="statusColor(item.status)" outlined>
                    {{ item.status }}
                  </v-chip>
                </template>
                <template v-slot:[`item.archived`]="{ item }">
                  <v-icon :color="item.archived ? 'primary' : 'error'" >{{ item.archived ? 'mdi-check' : 'mdi-close' }}</v-icon>
                </template>
              </v-data-table>
            </div>
            <div v-else>
              <v-data-iterator
                :items="filteredDevices"
                item-key="id"
                disable-pagination
                hide-default-footer
                fixed-header
                height="78vh"
                class="px-6"
              >
                <template v-slot:default="{ items }">
                  <v-row class="mt-3">
                    <v-col v-for="item in items" :key="item.id" cols="12" md="2">
                      <v-card
                        class="mx-auto"
                        :max-width="isMobile ? 344 : 500"
                      >
                        <v-img v-if="item.image" height="200" :src="item.image" @click="showDevice(item)" class="cpointer">
                          <v-chip v-if="item.status" dark :color="statusColor(item.status)" class="mt-3 mr-3 float-right"> {{ item.status }}</v-chip>
                          <div class="bottom-title">{{item.brand}}  {{item.model}}</div>
                        </v-img>
                        <v-img v-else height="200" src="https://wtec-uploads.s3.eu-central-1.amazonaws.com/assets/placeholder-image.png" contain>
                          <v-chip v-if="item.status" dark :color="statusColor(item.status)" class="mt-3 ml-3"> {{ item.status }}</v-chip>
                          <div class="bottom-title">{{item.model}}</div>
                        </v-img>
                        

                        <v-card-subtitle>
                          {{item.procat}}
                        </v-card-subtitle>

                        <v-card-actions>
                          <v-btn
                            color="primary darken-2"
                            text
                            @click="showDevice(item)"
                          >
                            Explore
                          </v-btn>

                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-col>
                  </v-row>
                </template>
              </v-data-iterator>
            </div>
          </v-fade-transition>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapGetters} from 'vuex'
import DeviceFilter from '@/components/Site/DeviceFilter'
import DeviceBulkActions from '@/components/Site/DeviceBulkActions'
import DeviceVisMenu from '@/components/Site/DeviceVisMenu'
import DeviceLayoutToggle from '@/components/Site/DeviceLayoutToggle'


export default {
  name: "Devices",
  components: {
    DeviceFilter,
    DeviceBulkActions,
    DeviceVisMenu,
    DeviceLayoutToggle,
  },
  data() {
    return {
      selectedDevices: [],
      search: '',
    }
    
  },
  computed: {
    ...mapGetters(['site', 'sitePermission', 'deviceFilters', 'UserRole', 'deviceHeaders', 'defaultDeviceHeaders', 'filteredDevices', 'deviceDialog', 'tableDevices', 'devicesLayout', 'isMobile']),
    headers() {
      if (Array.isArray(this.deviceHeaders)) {
        let headers = this.deviceHeaders.filter(el => el && el.visible)
        headers.forEach(h => {
          h.class = 'success--text subtitle-1'
        })
        return headers
      } else {
        return this.defaultDeviceHeaders.filter(el => el && el.visible)
      }
    },
    deviceTotal () {
      if (this.tableDevices) {
        if (this.deviceFilters.show_archived) {
          return this.tableDevices.length
        } else {
          return this.tableDevices.filter(d => !d.archived).length
        }
      } else {
        return 0
      }
    },
  },
  mounted() {
    this.$store.commit('setHeaderTitle', this.site.name + ' > Devices')
  },
  methods: {
    newDevice () {
      this.menu = false
      this.$store.commit('openCreateDeviceDialog')
    },
    searchDevices () {
      this.$store.commit('setDeviceSearch', this.search)
    },
    showDevice(item) {
      this.$store.commit('setDevice', item.id)
      setTimeout(() => {
        this.$store.commit('openDeviceDialog')
      }, 100)
    },
    statusColor(status) {
      let index = this.site.device_statuses.findIndex(a => a.name == status)
      return (index > -1) ? this.site.device_statuses[index].color : "#F1F1F1"
    },
    toggleDeviceFilter () {
      this.$store.commit('toggleDeviceFilter')
    }, 
    textColor(item) {
      return item.archived ? "error--text" : "hello"
    }
  },
};
</script>

<style scoped>
.fixed-toolbar {
  position: fixed;
  top: 92px;
  width: calc(100vw - 88px);
  z-index: 1;
}

.fixed-toolbar-mobile {
  position: fixed;
  top: 80px;
  width: 100vw;
  z-index: 1;
}

.scrollable-content {
  margin-top: 90px; /* Adjust based on the height of your toolbar */
  overflow-y: auto;
  height: calc(100vh - 198px); /* Adjust based on the height of your toolbar */
}

.bottom-title {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.5); /* Optional: Add a background for better readability */
  color: white; /* Optional: Change text color for better readability */
  padding: 5px; /* Optional: Add padding */
  margin: 0; /* Optional: Remove margin */
}
</style>