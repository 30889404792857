<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="item"
          :prepend-inner-icon="icon"
          label="Linked To"
          @click="dialog=true"
          ref="blurField"
        ></v-text-field>
      </v-col>
    </v-row>
    
    <v-dialog v-model="dialog" :width="isMobile ? '90%' : '40%'" scrollable>
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar class="elevation-2" color="grey lighten-3 mb-5">
            <v-toolbar-title class="headline primary--text">
              <span v-if="active.length > 0">Linked {{item}} </span>
              <span v-else>Select Linked Item</span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="save" color="primary">
              <v-icon>mdi-check</v-icon>
            </v-btn>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on" @click="close" color="error">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </template>
              <span>Cancel</span>
            </v-tooltip>
          </v-toolbar>
          <v-row class="mx-4">
            <v-col cols="8">
              <v-text-field
                v-model="search"
                clearable
                flat
                color="primary"
                hide-details
                prepend-inner-icon="mdi-magnify"
                label="Search"
                @input="handleSearch"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-switch
                v-model="expanded"
                label="Collapse/Expand All"
                inset
                @change="toggleExpand"
              ></v-switch>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-treeview
                ref="tree"
                :items="items"
                :search="search"
                item-key="key"
                :open.sync="open"
                :active.sync="active"
                return-object
                activatable
                hoverable
              >
                <template v-slot:prepend="{ item }">
                  <div class="cpointer">
                    <v-icon v-if="item.type === 'Site'">mdi-domain</v-icon>
                    <v-icon v-else-if="item.type === 'Floor'">mdi-layers-triple</v-icon>
                    <v-icon v-else-if="item.type === 'Location'">mdi-table</v-icon>
                    <v-icon v-else>mdi-devices</v-icon>
                  </div>
                </template>
                <template v-slot:label="{ item }">
                  <div class="cpointer">
                    <div>
                      {{item.name}} 
                      <span v-if="item.type === 'Device'" class="text-caption">|| {{item.code}}</span>
                    </div>
                  </div>
                </template>
              </v-treeview>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'MultimodalSelector',
    data: () => ({
      loading: true,
      dialog: false,
      items: [],
      search: '',
      open: [],
      active: [],
      updateTimer: null,
      expanded: false,
    }),
    props: ['selector_key', 'polymorphic_id', 'polymorphic_type'],
    computed: {
      ...mapGetters(['site', 'siteDevices', 'isMobile']),
      item () {
        if (this.active.length > 0) {
          if (this.active[0].type === 'Device') {
            return this.active[0].type + " || " + this.active[0].name + " || " + this.active[0].code
          } else {
            return this.active[0].type + " || " + this.active[0].name
          }       
        } else {
          return ''
        }
      }, 
      icon () {
        if (this.active[0].type === 'Site') {
          return 'mdi-domain'
        } else if (this.active[0].type === 'Floor') {
          return 'mdi-layers-triple'
        } else if (this.active[0].type === 'Location') {
          return 'mdi-table'
        } else if (this.active[0].type === 'Device') {
          return 'mdi-devices'
        } else {
          return 'mdi-close'
        }
      }
    },
    watch: { 
      selector_key: function() {
        this.activateByProp()
      }
    },
    created () {
      this.initialize()
    },

    methods: {
      initialize () {
        this.items = [{
          name: this.site.name,
          id: this.site.id,
          type: "Site",
          code: this.site.id,
          children: []
        }]
        this.items[0].children = JSON.parse(JSON.stringify(this.site.floors))
        this.items[0].children.forEach( (f) => {
          delete f.svg
          delete f.discarded_at
          delete f.created_at
          delete f.updated_at
          f.type = "Floor"
          f.key = "Floor_"+f.id
          f.children = f.locations
          f.children.forEach( (l) => {
            delete l.polygon
            delete l.discarded_at
            delete l.created_at
            delete l.updated_at
            delete l.location_type
            l.type = "Location"
            l.children = []
            l.key = "Location_"+l.id
          })
          delete f.locations
        })
        let devices = JSON.parse(JSON.stringify(this.siteDevices))
        devices.forEach( (d) => {
          delete d.sn
          delete d.mac
          delete d.ip
          delete d.patch
          delete d.note
          delete d.port
          delete d.purchase_date
          delete d.warranty_expiration_date
          delete d.icon
          delete d.on_plan
          delete d.discarded_at
          delete d.created_at
          delete d.updated_at
          delete d.location
          delete d.floor
          delete d.company
          d.type = "Device"
          d.key = "Device_"+d.id
          if (d.floor_id < 0 && d.location_id < 0) {
            this.items[0].children.push(d)
          } else if (d.floor_id > 0 && d.location_id < 0) {
            let floorIndex = this.items[0].children.findIndex(a => a.id === d.floor_id)
            this.items[0].children[floorIndex].children.push(d)
          } else if (d.floor_id > 0 && d.location_id > 0) {
            let floorIndex = this.items[0].children.findIndex(a => a.id === d.floor_id)
            let locationIndex = this.items[0].children[floorIndex].children.findIndex(a => a.id === d.location_id)
            try {
              this.items[0].children[floorIndex].children[locationIndex].children.push(d)
            } catch (error) {
              console.log(error)
              console.log(this.items)
              //needs further investigation
            }
          }
        })
        this.activateByProp()         
        this.loading = false
      },
      handleSearch(input) {
        if (input) {
          if (this.updateTimer) {
            clearTimeout(this.updateTimer)
          }
          this.updateTimer = setTimeout(() => {
            this.$refs.tree.updateAll(true)
            this.expanded = true
          }, 750)
          
        } else {
          if (this.updateTimer) {
            clearTimeout(this.updateTimer)
          }
          this.$refs.tree.updateAll(false)
          this.expanded = false
        }
      },
      close () {
        this.dialog = false
        this.$refs.blurField.blur()
      },
      save () {
        if (this.active[0].type !== 'Site') {
          this.$emit('linked', this.active[0].type, this.active[0].id)
        } else {
          this.$emit('linked', null, null)
        }
        this.close()
        this.$refs.blurField.blur()
      },
      toggleExpand () {
        if (this.expanded) {
          this.$refs.tree.updateAll(true)
          this.expanded = true
        } else {
          this.$refs.tree.updateAll(false)
          this.expanded = false
        }
      },
      activateByProp () {
        this.active = []
        if (this.polymorphic_id) {
          if (this.polymorphic_type === 'Floor') {
            let f = this.items[0].children.find((obj => { return obj.id === this.polymorphic_id && obj.type === this.polymorphic_type }))
            if (f) { this.active.push(f) }
          } else if (this.polymorphic_type === 'Location') {
            this.items[0].children.forEach( (f) => {
              let l = f.children.find((obj => { return obj.id === this.polymorphic_id && obj.type === this.polymorphic_type }))
              if (l) { this.active.push(l) }
            })
          } else if (this.polymorphic_type === 'Device') {
            let d = this.items[0].children.find((obj => { return obj.id === this.polymorphic_id && obj.type === this.polymorphic_type }))
            if (d) { 
              this.active.push(d) 
            } else {
              this.items[0].children.forEach( (f) => {
                d = f.children.find((obj => { return obj.id === this.polymorphic_id && obj.type === this.polymorphic_type }))
                if (d) { 
                  this.active.push(d) 
                } else {
                  f.children.forEach( (l) => {
                    d = l.children.find((obj => { return obj.id === this.polymorphic_id && obj.type === this.polymorphic_type }))
                    if (d) {this.active.push(d)}
                  })
                }
              })
            }
          }
        } else {
          this.active = [this.items[0]]
        }
        this.open = this.active
      }
    },
  }
</script>