<template>
  <div>
    <v-list-item @click="dialog = true">
      <v-list-item-icon>
        <v-icon>mdi-file-export-outline</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        Export Devices to Excel
      </v-list-item-content>
    </v-list-item>
    <v-dialog v-model="dialog">
      <v-card min-height="95vh">
        <v-toolbar class="elevation-2" color="grey lighten-3">
          <v-toolbar-title class="headline primary--text">Select Columns</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on" @click="download" color="primary">
                <v-icon>mdi-download</v-icon>
              </v-btn>
            </template>
            <span>Download</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on" @click="dialog = false"  color="error">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
            <span>Close</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text>       
          <v-row>
            <v-col cols="3">
              <v-subheader class="primary--text">Selected (drag to order or remove) </v-subheader>
              <v-card>
                <v-list dense nav>
                  <v-list-item-group color="primary">
                    <draggable v-model="selectedProperties" v-bind="dragOptions" @start="drag = true" @end="drag = false" :list="selectedProperties">
                      <transition-group type="transition" :name="!drag ? 'flip-list' : null">
                        <v-list-item
                          v-for="selection in selectedProperties"
                          :key="selection.text"
                        >
                          <v-list-item-icon>
                            <v-icon>{{ selection.icon }}</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title >{{ selection.text }}</v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-icon>
                            <v-icon @click="removeProperty (selection)">mdi-close</v-icon>
                          </v-list-item-icon>
                        </v-list-item>
                      </transition-group>
                    </draggable>
                  </v-list-item-group>
                </v-list> 
              </v-card>
            </v-col>
            <v-col cols="1"></v-col>
            <v-col cols="3">
              <v-subheader class="error--text">Unselected (drag or add)</v-subheader>
              <v-card>
                <v-list dense nav>
                  <v-list-item-group color="error">
                    <draggable v-model="unselectedProperties" v-bind="dragOptions" @start="drag = true" @end="drag = false" :list="unselectedProperties">
                      <transition-group type="transition" :name="!drag ? 'flip-list' : null">
                        <v-list-item
                          v-for="selection in unselectedProperties"
                          :key="selection.text"
                        >
                          <v-list-item-icon>
                            <v-icon>{{ selection.icon }}</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title >{{ selection.text }}</v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-icon>
                            <v-icon @click="addProperty(selection)">mdi-plus</v-icon>
                          </v-list-item-icon>
                        </v-list-item>
                      </transition-group>
                    </draggable>
                  </v-list-item-group>
                </v-list> 
              </v-card>
            </v-col>
            <v-col cols="2"></v-col>
            <v-col cols="3">
              <h6 class="mt-3">{{downloadableDevices.length}} Devices will be downloaded</h6>
              <v-checkbox
                v-model="forUpload"
                :label="`Add ids of device's relation to facilitate import`"
                class="mt-12"
              ></v-checkbox>
              <v-btn @click="download" color="primary" class="mt-12">
                <v-icon left>mdi-download</v-icon>
                Download Devices
              </v-btn>
            </v-col>
          </v-row>         
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import { utils, writeFile } from "xlsx"
import draggable from 'vuedraggable'

export default {
  name: "XlsDeviceDownloader",
  components: {
    draggable,
  },
  data() {
    return {
      exportData: [],
      filename: "Wikibuild - Devices.xlsx",
      dialog: false,
      drag: false,
      x: 0,
      y: 0,
      selectedProperties: [
        { text: 'ID', value: 'id', icon: 'mdi-numeric'},
        { text: 'Code', value: 'code', icon: 'mdi-pound'},
        { text: 'Name', value: 'name', icon: 'mdi-rename'},
        { text: 'Floor', value: 'floor', icon: 'mdi-layers-triple'},
        { text: 'Location', value: 'location', icon: 'mdi-table'},
        { text: 'Zone', value: 'zone', icon: 'mdi-table'},
        { text: 'Layer', value: 'layer', icon: 'mdi-layers-triple'},
        { text: 'Category', value: 'procat', icon: 'mdi-family-tree'},
        { text: 'Brand', value: 'brand', icon: 'mdi-devices'},
        { text: 'Model', value: 'model', icon: 'mdi-package'},
        { text: 'Supplier', value: 'supplier', icon: 'mdi-package-variant'},
        { text: 'Contractor', value: 'contractor', icon: 'mdi-tools'},
        { text: 'Serial Number', value: 'sn', icon: 'mdi-barcode'},
        { text: 'Mac Address', value: 'mac', icon: 'mdi-xml'},
        { text: 'Ip Address', value: 'ip', icon: 'mdi-lan'},
        { text: 'Status', value: 'status', icon: 'mdi-list-status'},
      ],
      unselectedProperties: [
        { text: 'Note', value: 'note', icon: 'mdi-note'},
        { text: 'Floor Code', value: 'floor_code', icon: 'mdi-layers-triple'},
        { text: 'Location Code', value: 'location_code', icon: 'mdi-table'},
        { text: 'Zone Code', value: 'zone_code', icon: 'mdi-table'},
        { text: 'Layer Code', value: 'layer_code', icon: 'mdi-layers-triple'},
        { text: 'Contractor Code', value: 'contractor_code', icon: 'mdi-tools'},
        { text: 'Supplier Code', value: 'supplier_code', icon: 'mdi-package-variant'},
        { text: 'Port', value: 'port', icon: 'mdi-server'},
        { text: 'Patch', value: 'patch', icon: 'mdi-ethernet'},
        { text: 'Purchase Date', value: 'purchase_date', icon: 'mdi-calendar-edit'},
        { text: 'Warranty Date', value: 'warranty_expiration_date', icon: 'mdi-calendar-alert'},
        { text: 'Created At', value: 'created_at', icon: 'mdi-clipboard-text-clock'},
        { text: 'Updated At', value: 'updated_at', icon: 'mdi-update'},
        { text: 'Image url', value: 'image', icon: 'mdi-image'},
        { text: 'Archived', value: 'archived', icon: 'mdi-archive-outline'},
      ],
      uploadProperties: [
        { text: 'site_id', value: 'site_id' },
        { text: 'floor_id', value: 'floor_id' },
        { text: 'location_id', value: 'location_id' },
        { text: 'product_id', value: 'product_id' },
        { text: 'brand_id', value: 'brand_id' },
        { text: 'procat_id', value: 'procat_id' },
        { text: 'layer_id', value: 'layer_id' },
        { text: 'zone_id', value: 'zone_id' },      
        { text: 'supplier_id', value: 'supplier_id' },
        { text: 'contractor_id', value: 'contractor_id' },
      ], 
      forUpload: false,
      
    };
  },
  props: ['devices'],
  computed: {
    ...mapGetters(['site', 'deviceFilters', 'filteredDevices']),
    dragOptions() {
      return {
        animation: 500,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      }
    },
    downloadableDevices () {
      let devices = []
      if (this.devices.length === 0) {
        devices = this.filteredDevices
      } else {
        devices = this.devices 
      }
      return devices
    },
  },
  methods: {
    download () {
      this.filename = 'WikiBuild - '+ this.site.name + ' - Devices.xlsx'
      this.createData (this.downloadableDevices)
      const data = utils.json_to_sheet(this.exportData)
      const wb = utils.book_new()
      utils.book_append_sheet(wb, data, 'data')
      writeFile(wb, this.filename)
    },
    removeProperty (selection) {
      this.selectedProperties = this.selectedProperties.filter( o => selection.text !== o.text )
      setTimeout(this.unselectedProperties.push(selection), 500)
      
    },
    addProperty (selection) {
      this.unselectedProperties = this.unselectedProperties.filter( o => selection.text !== o.text )
      setTimeout(this.selectedProperties.push(selection), 500)
    }, 
    createData (devices) {
      this.exportData = []
      let columns = []
      if (this.forUpload) {
        columns = this.selectedProperties.concat(this.uploadProperties)
      } else {
        columns = this.selectedProperties
      }
      devices.forEach( (d) => {
        let row = {}
        columns.forEach((p) => {
          if (p.value.includes('.')) {
            let subs = p.value.split('.')
            let nested1 = subs[0]
            let nested2 = subs[1]
            let nested3 = ''
            if (subs.length == 2) {
              row[p.value.toString()] = d[nested1]?.[nested2]
            } else if (subs.length == 3) {
              let nested3 = subs[2]
              row[p.value.toString()] = d[nested1]?.[nested2]?.[nested3]
            }
          } else {
            row[p.value] = d[p.value.toString()] 
          }
          
        })
        this.exportData.push(row)
      })
    }
  },
};
</script>

<style lang="scss" scoped>
  .flip-list-move {
    transition: transform 1s;
  }
  .no-move {
    transition: transform 0s;
  }
  .ghost {
    opacity: 0.5;
    background: #c8ebfb;
  }
</style>