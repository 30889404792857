<template>
  <v-card flat min-height="85vh" :key="componentKey">
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="3">
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="primary--text text-h6" colspan="2">Selected Contractors for this Site</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="c in selectedContractorsList"
                    :key="c.id"
                  >
                    <td>{{ c.name }}</td>
                    <td class="text-right">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-icon small v-on="on" @click="remove(c)">mdi-trash-can</v-icon>
                        </template>
                        <span>remove {{c.name}}</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
          <v-col cols="3">
            <v-simple-table dense>  
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="primary--text text-h6" colspan="2">Available Contractors</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="c in availableContractorsList"
                    :key="c.id"
                  >
                    <td>
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-icon small v-on="on" @click="add(c)">mdi-plus</v-icon>
                        </template>
                        <span>Add {{c.name}}</span>
                      </v-tooltip>
                    </td>
                    <td class="text-right">{{ c.name }}</td>
                    
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
          <v-col cols="4" class="offset-1">
            <v-simple-table dense class="no-hover">  
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="primary--text text-h6">Create a new Contractor</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <v-text-field v-model="editedItem.name" label="Name"></v-text-field>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <v-text-field v-model="editedItem.code" label="Code" ></v-text-field>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <v-btn @click="create" color="primary">Create</v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: "Contractors",
  data() {
    return {
      loading: true,
      editedItem: {
        name: '',
        code: '',
      },
      defaultItem: {
        name: '',
        code: '',
      },
      componentKey: 0
    }
  },
  computed: {
    ...mapGetters(['site', 'companies', 'StateUser']),
    selectedContractorsList () {
      console.log(this.site.contractors)
      return this.companies.filter(c => this.site.contractors.includes(c.id))
    }, 
    availableContractorsList () {
      return this.companies.filter(c => !this.site.contractors.includes(c.id) && c.company_type === 'Contractor')
    }
  },
  created () {
    this.initialize()
  },
  methods: {
    initialize () {
      this.loading = false
    },
    add (item) {
      this.save([...this.site.contractors, item.id])
    },
    remove (item) {
      this.save(this.site.contractors.filter(c => c !== item.id))
    },
    async save(array) {
      const Site = new FormData()
      Site.append("id", this.site.id)
      Site.append("contractors", JSON.stringify(array))
      let response = await this.$http.put('/site/sites', Site)
      if (response.data.error) {
        Array.isArray(response.data.error) ? response.data.error.forEach(m => this.$toast.error(m)) : this.$toast.error(response.data.error)
      } else {
        if (response.data.site.id === this.site.id) {
          await this.$store.dispatch('refreshSite', response.data.site.id)
          this.$toast.success('Contractors Updated')
        } else {
          this.$toast.error('Error saving Contractors')
        }
      }
    },
    async create() {
        try {
          const Company = new FormData()
          Company.append("name", this.editedItem.name)
          Company.append("code", this.editedItem.code)
          Company.append("company_type", 'Contractor')
          Company.append("tenant_id", this.site.tenant_id)

          let response = await this.$http.post('/admin/companies', Company)
          this.save([...this.site.contractors, response.data.company.id])
          this.$store.dispatch('refreshCompanies', this.site.id)
          this.editedItem = Object.assign({}, this.defaultItem)
        } catch (error) {
          this.$toast.error('Failed to create Contractor.')
        }
      },
  },
};
</script>

<style scoped>
/* Remove hover effect from v-simple-table */
.no-hover tbody tr:hover {
  background-color: transparent !important;
}
</style>