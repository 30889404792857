<template>
  <div>
    <div class="canvas" ref="canvas" id="canvas" @contextmenu="showContextMenu">
    </div>
    <context-menu/>
  </div>
  
</template>

<script>
import {mapGetters} from 'vuex'
import * as d3 from 'd3'
import ContextMenu from '@/components/V3/Floorplan/ContextMenu'

export default {
  name: "Plan",
  components: {
    ContextMenu
  },
  data() {
    return {
      svg: null,
      transform: null,
      floorplan: null,
      editing: false,
      width: null, 
      height: null,
      initialViewBox: [],
      viewBox: [],
      mouseX: 0,
      mouseY: 0,
      mouseXabs: 0,
      mouseYabs: 0,
      points: [], 
      lastPoint: null,
      startPoint: null,
      polygon: null,
      locCard: null,
      newSvg: null,
      deleteDialog: false,
      fontSize: 10,
      draggable: true,
    };
  },
  computed: {
    ...mapGetters(['site', 'floor', 'location', 'zone', 'sitePermission', 'locationsVisible', 'zonesVisible', 'labelsVisible' , 'planVisible']),
    polyLocations() {
      if (this.floor.id) {
        if (this.location.id) {
          let locations  = this.floor.locations.filter( o => o.polygon )
          return locations.filter(o => o.id !== this.location.id)
        } else {
          return this.floor.locations.filter( o => o.polygon )
        }
      } else {
        return []
      }
    },
    nonPolyLocations() {
      if (this.floor.id) {
        return this.floor.locations.filter( o => o.polygon == null )
      } else {
        return []
      }
    },
    polyZones() {
      if (this.floor.id) {
        if (this.zone.id) {
          let zones  = this.floor.zones.filter( o => o.polygon )
          return zones.filter(o => o.id !== this.zone.id)
        } else {
          return this.floor.zones.filter( o => o.polygon )
        }
      } else {
        return []
      }
    },
    nonPolyZones() {
      if (this.floor.id) {
        return this.floor.zones.filter( o => o.polygon == null )
      } else {
        return []
      }
    }
  },
  mounted () {
  },
  watch: {
    floor() {
      console.log('floor watcher for '+this.floor.name)
      if (this.floor?.id) {
        this.initD3()
        this.$store.commit('setHeaderTitle', this.site.name + ' > Plans > ' + this.floor.name + ' || ' + this.floor.code)
      }
    },
    location() {
      if (this.location) {
        this.$store.commit('setHeaderTitle', this.site.name + ' > Plans > ' + this.floor.name + ' || ' + this.floor.code + ' > ' + this.location.name + ' || ' + this.location.code)
      }
    },
    zone() {
      if (this.zone) { 
        this.$store.commit('setHeaderTitle', this.site.name + ' > Plans > ' + this.floor.name + ' || ' + this.floor.code + ' > ' + this.zone.name + ' || ' + this.zone.code)
      }
    },
    planVisible() {
      let element = document.getElementById("floorplan")
      if (element.classList.contains("d-none") && this.planVisible) {
        element.classList.remove("d-none")
      } else if (!this.planVisible) {
        element.classList.add("d-none")
      }
    },
    locationsVisible() {
      let element = document.getElementById("locations_svg")
      if (element.classList.contains("d-none") && this.locationsVisible) {
        element.classList.remove("d-none")
      } else if (!this.locationsVisible) {
        element.classList.add("d-none")
      }
    },
    zonesVisible () {
      let element = document.getElementById("zones_svg")
      if (element.classList.contains("d-none") && this.zonesVisible) {
        element.classList.remove("d-none")
      } else if (!this.zonesVisible) {
        element.classList.add("d-none")
      }
    },
    labelsVisible () {
      const elements = document.querySelectorAll('.labelText')
      elements.forEach(element => {
        if (element.classList.contains("d-none") && this.labelsVisible) {
          element.classList.remove("d-none")
        } else if (!this.labelsVisible) {
          element.classList.add("d-none")
        }
      })
    }
  },
  methods: {
    resetD3() {
      console.log('resetting')
      let canvas = document.getElementById("canvas")
      canvas.innerHTML = ''
      this.width = null
      this.height = null
      this.svg = null
      this.locCard = null
      this.initialViewBox = []
      this.viewBox = []
    },
    initD3() {
      this.resetD3()
      let that = this
      // select canvas and set h and w for svg
      let canvas = document.getElementById("canvas")
      let w = canvas.clientWidth
      that.width = w
      let h = canvas.clientHeight
      that.height = h

      // clear canvas, create main svg and set zoom and drag behavior
      that.svg = d3.select(canvas).html("")
        .append("svg")
        .attr("id", "mainSVG")
        .attr("width",  w)
        .attr("height",  h)
        .call(d3.zoom().on("zoom", function () {
          if (that.draggable) {
            that.svg.attr("transform", d3.zoomTransform(this))
            that.transform = d3.zoomTransform(this)
          }
        }))
        .append("g")
        .attr("id", "zoomG")

      // if User already panned or zoomed keep those coordinates   
      if (that.transform) {
        that.svg.attr("transform", that.transform)
      }

      // set a mousemove eventlistener to always have coordinates of mouse 
      d3.select("#zoomG").on('mousemove', function(event) {
        let coords = d3.pointer(event)
        that.mouseX = Math.round(coords[0])
        that.mouseY = Math.round(coords[1])
        let absCoords = d3.pointer(event, "body")
        that.mouseXabs = Math.round(absCoords[0])
        that.mouseYabs = Math.round(absCoords[1])
      });

      //add loccard div to canvas   
      this.locCard = d3.select(canvas).append("div").attr("id", "locCard")

      this.drawFloorplan()
    },

    drawFloorplan () {
      //empty zoomG group 
      d3.select("#zoomG").html("")
      let that = this

      if (that.floor) {
        if (that.floor?.svg?.length > 1) {
          // if a floor is selected and svg exists draw svg of floorplan or draw empty svg as container
          d3.xml(that.floor.svg)
            .then(data => {
              that.svg.node().append(data.documentElement)
              that.floorplan = d3.select("#zoomG").select("svg").attr("id", "floorplan")
              if (that.floorplan.attr("viewBox")) {
                that.initialViewBox = that.floorplan.attr("viewBox").split(" ")
                that.viewBox = that.floorplan.attr("viewBox").split(" ")
              } else {
                let h = that.floorplan.attr("height")
                let w = that.floorplan.attr("width")
                that.initialViewBox = [0, 0, w, h]
                that.viewBox = [0, 0, w, h]
                that.height = h
                that.width = w
              }
              that.floorplan.attr("viewBox", that.viewBox.join(" ")).attr("height", that.height).attr("width", that.width)
            })
        } else {
          that.floorplan = d3.select("#zoomG").append("svg").attr("id", "floorplan")
          that.floorplan.attr("height", that.height).attr("width", that.width)
        }
      }

      setTimeout(() => {
        this.drawLocations()
        setTimeout(() => {
          this.drawZones()
        }, 1000);
      }, 500);
      
      
    },

    drawLocations() {
      let that = this
      if (document.contains(document.getElementById("locations_svg"))) {
        document.getElementById("locations_svg").remove();
      }
      d3.select("#zoomG").append("svg")
                        .attr("id", "locations_svg")
                        .attr("height", that.height)
                        .attr("width", that.width)

      if (this.floor.locations) {
        let lg =  d3.select('#locations_svg')
                    .selectAll("g")
                    .data(that.polyLocations)
                    .enter()
                    .append("g")
                    .classed("location_group", true)
                    .attr("id", function(d) {return "loc_"+d.id})

        lg.append("polygon")
          .attr("points", function(d) {return d.polygon})
          .attr("id", function(d) { return "zone_poly_"+d.id})
          .attr("fill", "#000000")
          .attr("fill-opacity", "0.3")
          .classed("location_poly", true)
          .on('mouseover', function (d) {
            d3.select(this)
              .transition()
              .duration('50')
              .attr("fill", "#FF0000")
            that.locCard.html(d.target.__data__.name+" || "+d.target.__data__.code)
            that.locCard.transition()
                        .duration(50)
                        .style("opacity", 1)
                        .style("left", (that.mouseXabs+30) + "px")
                        .style("top", (that.mouseYabs-30) + "px")
          })
          .on('mouseout', function (d, i) {
            d3.select(this)
              .transition()
              .duration('50')
              .attr("fill", "#000000")
            that.locCard.transition()
                        .duration(50)
                        .style("opacity", 0);
          })
          .on('click', function (d) {
            //that.showItem(d.target.__data__.id)
          })

        lg.append("text")
          .text(function(d) {return d.code})
          .attr("x", function(d) {return that.calculateCentroid(d.polygon).x})
          .attr("y", function(d) {return that.calculateCentroid(d.polygon).y})
          .attr("fill", "red")
          .attr("text-anchor", "middle")
          .attr("font-size", that.fontSize)
          .classed("labelText", true)
          .classed("d-none", true)
      }

      if (this.location) {this.drawLocation()}
      
    },

    drawLocation() {
      let that = this
      document.querySelectorAll(".location_group_selected").forEach(el => el.remove());
      if (this.location.polygon) {
        d3.select('#locations_svg')
          .append('g')
          .classed("location_group_selected", true)
          .attr("id", "loc_" + this.location.id)
          .append("polygon")
          .attr("points", this.location.polygon)
          .attr("fill", "#2AAD74")
          .attr('fill-opacity', '0.3')
          .classed("location_poly_selected", true)
      }
    },

    drawZones() {
      let that = this
      if (document.contains(document.getElementById("zones_svg"))) {
        document.getElementById("zones_svg").remove();
      }
      d3.select("#zoomG").append("svg")
                        .attr("id", "zones_svg")
                        .classed("d-none", true)
                        .attr("height", that.height)
                        .attr("width", that.width)

      if (this.floor.zones) {
        let zg =  d3.select('#zones_svg')
                    .selectAll("g")
                    .data(that.polyZones)
                    .enter()
                    .append("g")
                    .classed("zone_group", true)
                    .attr("id", function(d) {return "zone_"+d.id})

        zg.append("polygon")
          .attr("points", function(d) {return d.polygon})
          .attr("id", function(d) { return "zone_poly_"+d.id})
          .attr("fill", "#000000")
          .attr("fill-opacity", "0.3")
          .classed("zone_poly", true)
          .on('mouseover', function (d) {
            d3.select(this)
              .transition()
              .duration('50')
              .attr("fill", "#FF0000")
            that.locCard.html(d.target.__data__.name+" || "+d.target.__data__.code)
            that.locCard.transition()
                        .duration(50)
                        .style("opacity", 1)
                        .style("left", (that.mouseXabs+30) + "px")
                        .style("top", (that.mouseYabs-30) + "px")
          })
          .on('mouseout', function (d, i) {
            d3.select(this)
              .transition()
              .duration('50')
              .attr("fill", "#000000")
            that.locCard.transition()
                        .duration(50)
                        .style("opacity", 0);
          })
          .on('click', function (d) {
            //that.showItem(d.target.__data__.id)
          })

        zg.append("text")
          .text(function(d) {return d.code})
          .attr("x", function(d) {return that.calculateCentroid(d.polygon).x})
          .attr("y", function(d) {return that.calculateCentroid(d.polygon).y})
          .attr("fill", "red")
          .attr("text-anchor", "middle")
          .attr("font-size", that.fontSize)
          .classed("labelText", true)
          .classed("d-none", true)
      }

      if (this.zone) {this.drawZone()}
      
    },

    drawZone() {
      let that = this
      document.querySelectorAll(".zone_group_selected").forEach(el => el.remove());
      if (this.zone.polygon) {
        d3.select('#zones_svg')
          .append('g')
          .classed("zone_group_selected", true)
          .attr("id", "zone_" + this.zone.id)
          .append("polygon")
          .attr("points", this.zone.polygon)
          .attr("fill", "#2AAD74")
          .attr('fill-opacity', '0.3')
          .classed("zone_poly_selected", true)
      }
    },

    calculateCentroid(cord) {
      let points = cord.split(' ')
                       .map(point => point.split(',').map(Number))
      points.push(points[0])
      let area = 0, C_x = 0, C_y = 0;
        
        for (let i = 0; i < points.length - 1; i++) {
            const x0 = points[i][0], y0 = points[i][1];
            const x1 = points[i + 1][0], y1 = points[i + 1][1];

            const cross = (x0 * y1) - (x1 * y0);
            area += cross;
            C_x += (x0 + x1) * cross;
            C_y += (y0 + y1) * cross;
        }

        area *= 0.5;
        C_x = (1 / (6 * area)) * C_x;
        C_y = (1 / (6 * area)) * C_y;

        return { x: C_x, y: C_y }
    },

    showContextMenu (e) {
      e.preventDefault()
      this.$store.commit('hideContextMenu')
      let x = this.mouseXabs
      let y = this.mouseYabs
      console.log(x+" || "+y)
      this.$nextTick(() => {
        this.$store.commit('showContextMenu', {x: x, y: y})
      })
    }
  },
}
</script>

<style lang="scss" scoped>
  .canvas {
    width: 100vw;
    height: calc(100vh - 90px);
    cursor: pointer;
  }

  #locCard,
  #selLocCard {
     position: fixed;
     text-align: center;
     padding: .5rem;
     background: #FFFFFF;
     border: 1px solid #000; 
     opacity: 0;
  }
</style>