<template>
  <v-app-bar
    height="64"
    fixed
    :color='headerColor'
    dark
  >
  <v-menu v-if="isMobile" offset-y bottom right nudge-bottom="10" transition="scale-transition">
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="mr-0" v-bind="attrs" v-on="on" icon>
          <v-icon style="font-size: 28px"> mdi-menu</v-icon>
        </v-btn>
      </template>
      <v-card min-width="300px">
        <v-card-text>
          <v-row>
            <v-col 
              v-for="shortcut in items"
              :key="shortcut.title"
              cols="6"
              class="text-center cpointer shortcut-icon"
              @click="goTo(shortcut.link)"
            >
              
              <v-avatar>
                <v-icon x-large>{{ shortcut.icon }}</v-icon>
              </v-avatar>
              <v-card-subtitle class="py-0 px-5">
                {{ shortcut.title }}
              </v-card-subtitle>
              
            </v-col>
            <v-col 
              v-if="site.id"
              cols="12"
              class="text-center cpointer shortcut-icon"
              @click="exitSite"
            >
              
              <v-avatar>
                <v-icon x-large>mdi-exit-to-app</v-icon>
              </v-avatar>
              <v-card-subtitle class="py-0 px-5">
                Exit Site
              </v-card-subtitle>
              
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-menu>

    <router-link v-else to="/">
      <v-img
        class="ml-n2"
        max-height="40"
        max-width="40"
        src="https://wtec-uploads.s3.eu-central-1.amazonaws.com/assets/header-logo.svg"
      ></v-img>
    </router-link>
    
    <v-toolbar-title v-if="!isMobile" class="ml-8">{{HeaderTitle}}</v-toolbar-title>
    <v-spacer></v-spacer>
    <!-- <v-btn style="font-size: 28px" icon class="mr-2" to="/support">
      <v-icon style="font-size: 28px" color="rgba(255, 255, 255, 0.35)">mdi-lifebuoy</v-icon> 
    </v-btn> -->
    
    <v-menu offset-y bottom right nudge-bottom="15" min-width="300px" transition="scale-transition">
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="mr-0" v-bind="attrs" v-on="on" rounded text>
          <v-icon style="font-size: 28px" :left="!isMobile"> mdi-account</v-icon>
          <span v-if="!isMobile">{{StateUser.email}}</span> 
        </v-btn>
      </template>
      <v-list>
        <div class="text-h5 grey--text text--darken-3 px-4 pt-4">{{StateUser.fname}} {{StateUser.lname}}</div>
        <div class="subtitle-2 primary--text font-weight-regular px-4">{{UserRole}}</div>
        <v-list-item-group color="primary">
          <v-list-item
            v-for="(item, i) in account"
            :key="i"
            :to="item.link === '#' ? null : item.link">
              <v-list-item-icon class="mr-4">
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title >{{ item.text }}</v-list-item-title>
              </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
        <v-list-item-group color="primary" v-if="UserRole === 'Super Admin'">
          <v-list-item
            v-for="(item, i) in superAdminAccount"
            :key="i"
            :to="item.link === '#' ? null : item.link">
              <v-list-item-icon class="mr-4">
                <v-icon>
                  {{ item.icon }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.text }}</v-list-item-title>
              </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
        <v-list>
          <v-list-item @click="toggleMobile">
            <v-list-item-icon class="mr-4">
              <v-icon color="primary">
                mdi-cog
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title >toggle mobile</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <div class="d-flex justify-center my-3">
          <v-btn
            width="80%"
            large
            outlined
            color="primary"
            class="text-capitalize"
            @click="signOut">Sign Out
          </v-btn>
        </div>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
  import {mapGetters} from 'vuex'
  
  export default {
    name: 'Header',
    data: () => ({
      globalItems: [
        //{ title: 'Home', icon: 'mdi-home', link: '/dashboard', userRole: 1 },
        { title: 'Sites', icon: 'mdi-office-building', link: '/sites', userRole: 1 },
        //{ title: 'Users', icon: 'mdi-account-multiple', link: '/tenant/users', userRole: 2 },
        //{ title: 'Products', icon: 'mdi-package-variant', link: '/tenant/products', userRole: 2 },
        { title: 'Logs', icon: 'mdi-clipboard-text-clock', link: '/tenant/logs', userRole: 2},
      ],
      siteItems: [
        { title: 'Dashboard', icon: 'mdi-monitor-dashboard', link: 'dashboard', permission: 1 },
        { title: 'Devices', icon: 'mdi-devices', link: 'devices', permission: 1 },
        { title: 'Locations', icon: 'mdi-floor-plan', link: 'locations', permission: 1 },     
        //{ title: 'Connections', icon: 'mdi-swap-horizontal', link: 'connections', permission: 1 },
        //{ title: 'Attachments', icon: 'mdi-paperclip', link: 'attachments', permission: 1 },
        { title: 'Vault', icon: 'mdi-shield-lock', link: 'vault', permission: 3 },
        //{ title: 'Contacts', icon: 'mdi-card-account-details', link: 'contacts', permission: 1 },
        { title: 'Access', icon: 'mdi-security', link: 'access', permission: 3 },
        { title: 'Logs', icon: 'mdi-clipboard-text-clock', link: 'logs', permission: 2 },
        //{ title: 'Config', icon: 'mdi-cog', link: 'config', permission: 3 },
        //{ title: 'Support', icon: 'mdi-lifebuoy', link: 'support', permission: 1 },
      ],
      account: [
        { text: 'Profile', icon: 'mdi-account', color: 'primary', link: '/profile' },
      ],
      superAdminAccount: [
        { text: 'Administration', icon: 'mdi-application-cog', color: 'primary', link: '/admin/dashboard' }
      ],
    }),
    computed: {
      ...mapGetters(['UserRole', 'StateUser', 'HeaderTitle', "isMobile", 'site', 'sitePermission']),
      headerColor() {
        if (window.location.hostname == "app.wikibuild.io") {
          return 'primary'
        } else {
          return 'info'
        }
      },
      items() {
        if (this.site.id) {
          return this.siteItems.filter(item => item.permission <= this.sitePermission)
        } else {
          return this.globalItems.filter(item => item.userRole <= this.StateUser.role)
        }
      },
    },
    mounted() {
      this.$store.commit('setHeaderTitle', 'WikiBuild')
    },
    methods: {
      async signOut() {
        await this.$store.dispatch('LogOut')
        this.$router.push('/login')
        this.$store.dispatch('resetAllStates')
        localStorage.clear()
      },
      toggleMobile() {
        this.$store.commit('toggleMobile')
      },
      goTo(link) {
        if (this.site.id) {
          let url = '/site/' + this.$route.params.site_id + '/' + link
          this.$router.push(url)
        } else {
          this.$router.push(link)
        }
      },
      exitSite () {
        this.$store.commit('unsetSite')
        this.$router.push('/sites')
      }
    }
  };
</script>
