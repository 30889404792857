import axios from 'axios'

// Define the initial state in a function to allow resetting
const getDefaultState = () => ({
  user: null,
  token: null,
  isMobile: false,
})

const state = getDefaultState()

const getters = {
  isAuthenticated: state => !!state.user,    
  StateUser: state => state.user,
  UserRole: state => {
    if (state.user) {
      if (state.user.role === 5) return "Super Admin"
      else if (state.user.role === 4) return "Library Admin"
      else if (state.user.role === 3) return "Integrator"
      else if (state.user.role === 2) return "Building Owner"
      else return "User"
    } else {
      return "not logged in"
    }
  },
  Token: state => state.token,
  isMobile: state => state.isMobile,
}

const actions = {
  async LogIn({ commit }, User) {
    let response = await axios.post('/admin/users/login', User)
    commit('setUser', response.data.user)
    commit('setToken', response.data.token)
  },
  async LogOut({ commit }) {
    commit('resetAuthState')
  },
}

const mutations = {
  setUser(state, user) {
    state.user = user
  },
  setToken(state, token) {
    state.token = token
  },
  resetAuthState(state) {
    Object.assign(state, getDefaultState())
  },
  toggleMobile(state) {
    state.isMobile = !state.isMobile
  },
  setMobile(state, value) {
    state.isMobile = value
  },

}

export default {
  state,
  getters,
  actions,
  mutations,
}
