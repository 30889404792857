<template>
  <v-menu
    v-model="filterMenu"
    :close-on-content-click="false"
    min-width="500"
    offset-x
  >
    <template v-slot:activator="{ on, attrs }">
      <v-badge
        bordered
        :content="filterCount"
        :value="filterCount"
        color="primary"
        overlap
      >
        <v-btn
          icon
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-filter</v-icon>
        </v-btn>
      </v-badge>
      
    </template>

    <v-card>
      <v-toolbar dense flat>
        <v-toolbar-title>Filters</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="setFilters">
          <v-icon>mdi-filter-check</v-icon>
        </v-btn>
        <v-btn icon  @click="clearAllFilters">
          <v-icon>mdi-filter-remove</v-icon>
        </v-btn>
        <v-btn icon  @click="filterMenu = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-tree-select
          v-model="filtered.locations"
          autocomplete
          allow-select-parents
          selectable
          chips
          multiple
          :items="filterableLocations"
          clearable
          item-key="id"
          item-text="full"
          label="Locations"
          selection-type="leaf"
        >
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index <= 2" close @click:close="removeLocation(item)">
              <span>{{ item.name }} || {{ item.code }}</span>
            </v-chip>
            <span
              v-if="index === 3"
              class="grey--text text-caption"
            >
              (+{{ filtered.locations.length - 3 }} others)
            </span>
          </template>
        </v-tree-select>
        <v-tree-select
          v-model="filtered.procats"
          autocomplete
          allow-select-parents
          selectable
          chips
          multiple
          :items="filterableProcats"
          clearable
          item-key="id"
          item-text="name"
          label="Categories"
          selection-type="leaf"
          open-one-path
        >
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index <= 2" close @click:close="removeProcat(item)">
              <span>{{ item.full }}</span>
            </v-chip>
            <span
              v-if="index === 3"
              class="grey--text text-caption"
            >
              (+{{ filtered.procats.length - 3 }} others)
            </span>
          </template>
        </v-tree-select>
        <v-select
          v-model="filtered.statuses"
          :items="site.device_statuses"
          item-text="name"
          item-value="name"
          label="Device Statuses"
          clearable
          multiple
          chips
          deletable-chips
        ></v-select>
        <v-select
          v-model="filtered.layers"
          :items="site.layers"
          item-text="name"
          item-value="id"
          label="Layers"
          clearable
          multiple
          chips
          deletable-chips
        ></v-select>
        <v-select
          v-model="filtered.suppliers"
          :items="availableSuppliersList"
          item-text="name"
          item-value="id"
          label="Suppliers"
          clearable
          multiple
          chips
          deletable-chips
        ></v-select>
        <v-select
          v-model="filtered.contractors"
          :items="availableContractorsList"
          item-text="name"
          item-value="id"
          label="Contractors"
          clearable
          multiple
          chips
          deletable-chips
        ></v-select>
        <device-archive-toggle/>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import {mapGetters} from 'vuex'
import DeviceArchiveToggle from '@/components/Site/DeviceArchiveToggle'

export default {
  name: "DeviceFilter",
  components: {
    DeviceArchiveToggle,
  },
  data() {
    return {
      filterMenu: false,
      filtered: {
        locations: [],
        layers: [],
        statuses: [],
        procats: [],
        suppliers: [],
        contractors: [],
        show_archived: false
      },
      procats: []
    };
  },
  computed: {
    filterCount () {
      console.log(this.filtered.locations?.length)
      console.log(this.filtered.locations?.length)
      console.log(this.filtered.locations?.length)
      console.log(this.filtered.locations?.length)
      console.log(this.filtered.locations?.length)
      console.log(this.filtered.locations?.length)
      if (this.activeFilters) {
        return  this.filtered.locations?.length +
                this.filtered.layers?.length +
                this.filtered.statuses?.length +
                this.filtered.procats?.length +
                this.filtered.suppliers?.length +
                this.filtered.contractors?.length
      } else {
        return false
      }
      
    },
    activeFilters () {
      if (  this.filtered.locations?.length > 0 ||
            this.filtered.layers?.length > 0 ||
            this.filtered.statuses?.length > 0 ||
            this.filtered.procats?.length > 0 ||
            this.filtered.suppliers?.length > 0 ||
            this.filtered.contractors?.length > 0) {
        return true
      } else {
        return false
      }
    },
    filterableLocations () {
      let loc = []
      this.site.floors.forEach((f) => {
        f.children = f.locations
        f.full = f.name+" || "+f.code
        f.children.forEach((l) => {
          l.full = l.name+" || "+l.code
        })
        loc.push(f)
      })
      return loc
    },
    filterableProcats () {
      let key = 1
      let tree = []
      this.procats.forEach((cat) => {
        const i = tree.findIndex(e => e.name === cat.main)
        if (i > -1) {
          const j = tree[i].children.findIndex(e => e.name === cat.sub1)
          if (j > -1) {
            const k = tree[i].children[j].children.findIndex(e => e.name === cat.sub2)
            if (k > -1) {
              const l = tree[i].children[j].children[k].children.findIndex(e => e.name === cat.sub3)
              if (l > -1) {
                
              } else {
                tree[i].children[j].children[k].children.push({
                  id: key,
                  name: cat.sub3,
                  filter: cat.id, 
                  full: cat.name,
                })
                key++
              }
            } else {
              tree[i].children[j].children.push({
                id: key,
                name: cat.sub2,
                filter: cat.id,
                full: cat.name,
                children: [],
              })
              key++
            }
          } else {
            tree[i].children.push({
              id: key,
              name: cat.sub1,
              filter: cat.id,
              full: cat.name,
              children: [],
            })
            key++
          }
        } else {
          tree.push({
            id: key,
            name: cat.main,
            filter: cat.id,
            full: cat.name,
            children: [],
          })
          key++
        }
      })
      return tree
    },
    availableContractorsList () {
      return this.companies.filter(c => this.site.contractors.includes(c.id))
    },
    availableSuppliersList () {
      let supplierIds = [...new Set(this.siteDevices.map(device => device.supplier_id))]
      return this.companies.filter(company => supplierIds.includes(company.id))
    },
    ...mapGetters(['site', 'sitePermission', 'deviceFilterVisible', 'deviceFilters', 'companies', 'siteDevices']),
  },
  mounted() {
    this.initialize()
  },
  methods: {
    async initialize () {
      let response = await this.$http.get('/global/procat_list')
      this.procats = response.data.procats
      this.filtered = this.deviceFilters
      this.filterMenu = false
    },
    clearAllFilters () {
      this.filtered.locations = []
      this.filtered.statuses = []
      this.filtered.procats = []
      this.filtered.layers = []
      this.filtered.suppliers = []
      this.filtered.contractors = []
      this.$store.commit('setDeviceFilters', this.filtered)
      this.$store.commit('saveDeviceFilters')
      this.filterMenu = false
    },

    setFilters () {
      this.$store.commit('setDeviceFilters', this.filtered)
      this.$store.commit('saveDeviceFilters')
      this.filterMenu = false
    },

    removeProcat(item) {
      const index = this.filtered.procats.indexOf(item)
      if (index >= 0) this.filtered.procats.splice(index, 1)
      this.$store.commit('setDeviceFilters', this.filtered)
      this.$store.commit('saveDeviceFilters')
    },

    removeLocation(item) {
      const index = this.filtered.locations.indexOf(item)
      if (index >= 0) this.filtered.locations.splice(index, 1)
      this.$store.commit('setDeviceFilters', this.filtered)
      this.$store.commit('saveDeviceFilters')
    },
  },
};
</script>