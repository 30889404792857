import axios from 'axios'

const getDefaultState = () => ({
  site: {},
  siteDevices: [],
  device: {},
  floor: {},
  location: {},
  zone: {},
  sitePermission: 0,
  deviceLogs: [],
  siteLogs: [],
  companies: [],
  products: [],
  procats: [],
  siteAccess: 0,
  deviceFilters: {
    locations: [],
    layers: [],
    statuses: [],
    procats: [],
    suppliers: [],
    contractors: [],
    show_archived: false,
  },
  deviceSearch: '',
  defaultDeviceHeaders: [
    { order: 0, text: 'ID', value: 'id', visible: false , default: false },
    { order: 1, text: 'Image', value: 'image', visible: true , default: true },
    { order: 2, text: 'Code', value: 'code', visible: true , default: true },
    { order: 3, text: 'Name', value: 'name', visible: false , default: false },
    { order: 4, text: 'Category ID', value: 'procat_id', visible: false , default: false },
    { order: 5, text: 'Category', value: 'procat', visible: true , default: true },
    { order: 6, text: 'Brand ID', value: 'brand_id', visible: false , default: false },
    { order: 7, text: 'Brand', value: 'brand', visible: true , default: true },
    { order: 8, text: 'Model', value: 'model', visible: true , default: true },
    { order: 9, text: 'Floor ID', value: 'floor_id', visible: false , default: false },
    { order: 10, text: 'Floor', value: 'floor', visible: true , default: true },
    { order: 11, text: 'Floor Code', value: 'floor_code', visible: false , default: false },
    { order: 12, text: 'Location ID', value: 'location_id', visible: false , default: false },
    { order: 13, text: 'Location', value: 'location', visible: true , default: true },
    { order: 14, text: 'Location Code', value: 'location_code', visible: false , default: false },
    { order: 15, text: 'Zone ID', value: 'zone_id', visible: false , default: false },
    { order: 16, text: 'Zone', value: 'zone', visible: true , default: true },
    { order: 17, text: 'Zone Code', value: 'zone_code', visible: false , default: false },
    { order: 18, text: 'Layer ID', value: 'layer_id', visible: false , default: false },
    { order: 19, text: 'Layer', value: 'layer', visible: false , default: false },
    { order: 20, text: 'Layer Code', value: 'layer_code', visible: false , default: false },
    { order: 21, text: 'Contractor ID', value: 'contractor_id', visible: false , default: false },
    { order: 22, text: 'Contractor', value: 'contractor', visible: true , default: true },
    { order: 23, text: 'Contractor Code', value: 'contractor_code', visible: false , default: false },
    { order: 24, text: 'Supplier ID', value: 'supplier_id', visible: false , default: false },
    { order: 25, text: 'Supplier', value: 'supplier', visible: true , default: true },
    { order: 26, text: 'Supplier Code', value: 'supplier_code', visible: false , default: false },
    { order: 27, text: 'Serial Number', value: 'sn', visible: false , default: false },
    { order: 28, text: 'Mac Address', value: 'mac', visible: false , default: false },
    { order: 29, text: 'IP Address', value: 'ip', visible: false , default: false },
    { order: 30, text: 'Port', value: 'port', visible: false , default: false },
    { order: 31, text: 'Patch', value: 'patch', visible: false , default: false },
    { order: 32, text: 'Note', value: 'note', visible: false , default: false },
    { order: 33, text: 'Status', value: 'status', visible: true , default: true },
    { order: 34, text: 'Archived', value: 'archived', visible: false , default: false },
    { order: 35, text: 'Created at', value: 'created_at', visible: false , default: false },
    { order: 36, text: 'Updated at', value: 'updated_at', visible: false , default: false },
  ],
  deviceHeaders: [],
})

const state = getDefaultState()

const getters = {
  site: state => state.site,
  siteDevices: state => state.siteDevices,
  device: state => state.device,
  floor: state => state.floor,
  location: state => state.location,
  zone: state => state.zone,
  sitePermission: state => state.sitePermission,
  deviceLogs: state => state.deviceLogs,
  siteLogs: state => state.siteLogs,
  companies: state => state.companies,
  products: state => state.products,
  procats: state => state.procats,
  siteAccess: state => state.siteAccess,
  deviceFilters: state => state.deviceFilters,
  deviceSearch: state => state.deviceSearch,
  defaultDeviceHeaders: state => state.defaultDeviceHeaders,
  deviceHeaders: state => state.deviceHeaders,
  
  tableDevices: state => {  
    if (state.siteDevices.length === 0) {
      return []
    }
    let devices = state.siteDevices
    let tableDevices = []

    devices.forEach((d) => {
      let product = state.products.find(p => p.id === d.product_id)
      let procat = state.procats.find(p => p.id === product.procat_id)  
      let floor = state.site.floors.find(f => f.id === d.floor_id) 
      let location = null
      let zone = null
      if (floor) {
        location = floor.locations.find(l => l.id === d.location_id)
        zone = floor.zones.find(z => z.id === d.zone_id)
      }
      let layer = state.site.layers.find(l => l.id === d.layer_id)
      let contractor = state.companies.find(c => c.id === d.contractor_id)
      let supplier = state.companies.find(c => c.id === d.supplier_id) 
      let brand = state.companies.find(c => c.id === product.brand_id) 

      let tableDevice = {
        id: d.id,
        site_id: d.site_id,
        product_id: d.product_id,
        image: product ? product.image : null,
        code: d.code,
        name: d.name,
        procat_id: procat ? procat.id : null,
        procat: procat ? procat.name : null,
        brand_id: brand ? brand.id : null,
        brand: brand ? brand.name : null,
        model: product ? product.product_model : null,
        floor_id: floor ? floor.id : null,
        floor: floor ? floor.name : null,
        floor_code: floor ? floor.code : null,
        location_id: location ? location.id : null,
        location: location ? location.name : null,
        location_code: location ? location.code : null,
        zone_id: zone ? zone.id : null,
        zone: zone ? zone.name : null,
        zone_code: zone ? zone.code : null,
        layer_id: layer ? layer.id : null,
        layer: layer ? layer.name : null,
        layer_code: layer ? layer.code : null,
        sn: d.sn,
        mac: d.mac,
        ip: d.ip,
        port: d.port,
        patch: d.patch,
        note: d.note,
        status: d.status,
        archived: d.archived,
        contractor_id: contractor ? contractor.id : null,
        contractor: contractor ? contractor.name : null,
        contractor_code: contractor ? contractor.code : null,
        supplier_id: supplier ? supplier.id : null,
        supplier: supplier ? supplier.name : null,
        supplier_code: supplier ? supplier.code : null,
        purchase_date: d.purchase_date,
        warranty_expiration_date: d.warranty_expiration_date,
        created_at: d.created_at,
        updated_at: d.updated_at,
      }
      tableDevices.push(tableDevice)
    })

    return tableDevices
  },
  filteredDevices: (state, getters) => {
    let devices = getters.tableDevices
  
    const { statuses, show_archived, procats, locations, layers, suppliers } = state.deviceFilters
  
    const statusSet = new Set(statuses)
    const procatSet = new Set(procats?.map(item => item.filter))
    const locationSet = new Set(locations?.map(item => item.id))
    const layerSet = new Set(layers)
    const supplierSet = new Set(suppliers?.map(item => item.id))
  
    devices = devices.filter(device => {
      if (statuses?.length > 0 && !statusSet.has(device.status)) {
        return false
      }
  
      if (!show_archived && device.archived) {
        return false
      }
  
      if (procats?.length > 0 && !procatSet.has(device.procat_id)) {
        return false
      }
  
      if (locations?.length > 0 && !locationSet.has(device.location_id)) {
        return false
      }
  
      if (layers?.length > 0 && !layerSet.has(device.layer_id)) {
        return false
      }
  
      if (suppliers?.length > 0 && !supplierSet.has(device.supplier_id)) {
        return false
      }
  
      return true
    })
    // Search functionality
    const search = state.deviceSearch ? state.deviceSearch.toLowerCase() : ''
    if (search.length > 0) {
      devices = devices.filter(device => {
        return Object.values(device).some(value =>
          String(value).toLowerCase().includes(search)
        )
      })
    }

    return devices;
  },

  suppliers: state => {
    return state.companies.filter(c => c.company_type === 'Supplier')
  },
  contractors: state => {
    return state.companies.filter(c => c.company_type === 'Contractor' && state.site.contractors.includes(c.id))
  },
}

const actions = {
  async setSite({commit}, id) {
    let response = await axios.get('/site/sites', { params: { id: id } })
    let site = response.data.site
    site.floors.sort((a,b) => {return a.id - b.id})
    site.floors.forEach( f => {f.locations.sort((a,b) => {return a.id - b.id})})
    await commit('setSite', site)
    await commit('setLogs', response.data.logs)
    await commit('setCompanies', response.data.companies)
    await commit('setDevices', response.data.devices)
    await commit('setProducts', response.data.products)
    await commit('setProcats', response.data.procats)
  },
  async refreshSite({commit}, id) {
    let response = await axios.get('/site/refresh_site', { params: { id: id } })
    let site = response.data.site
    site.floors.sort((a,b) => {return a.id - b.id})
    site.floors.forEach( f => {f.locations.sort((a,b) => {return a.id - b.id})})
    commit('setSite', site)
  },
  async refreshLogs({commit}, site_id) {
    let response = await axios.get('/site/refresh_logs', { params: { id: site_id } })
    commit('setLogs', response.data.logs)
  },
  async refreshCompanies({commit}, site_id) {
    let response = await axios.get('/site/refresh_companies', { params: { id: site_id } })
    commit('setCompanies', response.data.companies)
  },
  async refreshDevices({commit}, site_id) {
    let response = await axios.get('/site/refresh_devices', { params: { id: site_id } })
    commit('setDevices', response.data.devices)
  },
  async refreshProducts({commit}, site_id) {
    let response = await axios.get('/site/refresh_products', { params: { id: site_id } })
    commit('setProducts', response.data.products)
  },
  async refreshProcats({commit}, site_id) {
    let response = await axios.get('/site/refresh_procats', { params: { id: site_id } })
    commit('setProcats', response.data.procats)
  },

}

const mutations = {
  resetSiteState(state) {
    Object.assign(state, getDefaultState())
  },
  setSite (state, site) {
    state.site = site
  },
  setLogs (state, logs) {
    state.siteLogs = logs
  },
  setCompanies (state, companies) {
    state.companies = companies
  },
  setDevices (state, devices) {
    state.siteDevices = devices
  },
  setProducts (state, products) { 
    state.products = products
  },
  setProcats (state, procats) {
    state.procats = procats
  },
  unsetSite (state) {
    Object.assign(state, getDefaultState())
  },
  setAccess (state, access) {
    state.siteAccess = access
  },
  setPermission (state, permission) {
    state.sitePermission = permission
  },
  unsetPermission (state) {
    state.sitePermission = 0
  },
  setDevice (state, id) {
    let device = state.siteDevices[state.siteDevices.findIndex(a => a.id === id)]
    device.product = state.products[state.products.findIndex(a => a.id === device.product_id)]
    device.product.procat = state.procats[state.procats.findIndex(a => a.id === device.product.procat_id)]  
    state.device = device
  },
  updateDevice (state, device) {
    Object.assign(state.siteDevices[state.siteDevices.findIndex(a => a.id === device.id)], device)
  },
  setFloor (state, id) {
    state.floor = state.site.floors[state.site.floors.findIndex(a => a.id === id)]
  },
  setLocation (state, location) {
    let floorIndex = state.site.floors.findIndex(a => a.id === location.floor_id)
    state.floor = state.site.floors[floorIndex]
    state.location = state.site.floors[floorIndex].locations[state.site.floors[floorIndex].locations.findIndex(b => b.id === location.id)]
  },
  setZone (state, zone) {
    let floorIndex = state.site.floors.findIndex(a => a.id === zone.floor_id)
    state.floor = state.site.floors[floorIndex]
    state.zone = state.site.floors[floorIndex].zones[state.site.floors[floorIndex].zones.findIndex(b => b.id === zone.id)]
  },
  unsetFloorLocationZone (state) {
    state.floor = {}
    state.location = {}
    state.zone = {}
  },
  setDeviceLogs (state, logs) {
    state.deviceLogs = logs
  },
  setDeviceSearch (state, search) {
    state.deviceSearch = search 
  },
  setDeviceFilters (state, filters) {
    if (filters.length == 0) {
      state.deviceFilters.locations = []
      state.deviceFilters.layers = []
      state.deviceFilters.statuses = []
      state.deviceFilters.procats = []
    } else {
      state.deviceFilters = filters
    } 
  },
  async saveDeviceFilters (state) {
    const Filters = new FormData()
    Filters.append("id", state.siteAccess)
    Filters.append("filters", JSON.stringify(state.deviceFilters))
    let response = await axios.post('/site/filters', Filters)
    if (response.data.error) {
      Array.isArray(response.data.error) ? response.data.error.forEach(m => console.log(m)) : console.log(response.data.error)
    }
  },
  setDeviceHeaders (state, headers) {
    if (headers.length == 0) {
      state.deviceHeaders = [...state.defaultDeviceHeaders]
    } else {
      state.deviceHeaders = [...headers]
    }
  },
  async saveDeviceHeaders (state) {
    const Headers = new FormData()
    Headers.append("id", state.siteAccess)
    Headers.append("headers", JSON.stringify(state.deviceHeaders))
    let response = await axios.post('/site/headers', Headers)
    if (response.data.error) {
      Array.isArray(response.data.error) ? response.data.error.forEach(m => console.log(m)) : console.log(response.data.error)
    }
  },

  deleteFloor (state, id) {
    state.site.floors = state.site.floors.filter(floor => floor.id !== id)
  },
  updateFloor (state, floor) {
    Object.assign(state.site.floors[state.site.floors.findIndex(a => a.id === floor.id)], floor)
  },
  createFloor (state, floor) {
    state.site.floors.push(floor)
  },
  orderFloors(state, floorOrder) {
    const [key, direction] = floorOrder.split(' ')
    const isAscending = direction === "↑"
    const compare = (a, b) => isAscending ? String(a[key]).localeCompare(String(b[key])) : String(b[key]).localeCompare(String(a[key]))

    state.site.floors.sort(compare)
  },
  
  deleteLocation (state, location) {
    let floorIndex = state.site.floors.findIndex(a => a.id === location.floor_id)
    state.site.floors[floorIndex].locations = state.site.floors[floorIndex].locations.filter(a => a.id !== location.id)
  },
  updateLocation (state, location) {
    let floorIndex = state.site.floors.findIndex(a => a.id === location.floor_id)
    Object.assign(state.site.floors[floorIndex].locations[state.site.floors[floorIndex].locations.findIndex(a => a.id === location.id)], location)
  },
  createLocation (state, location) {
    let floorIndex = state.site.floors.findIndex(a => a.id === location.floor_id)
    state.site.floors[floorIndex].locations.push(location)
  },
  orderLocations(state, locationOrder) {
    const [key, direction] = locationOrder.split(' ')
    const isAscending = direction === "↑"
    const compare = (a, b) => isAscending ? String(a[key]).localeCompare(String(b[key])) : String(b[key]).localeCompare(String(a[key]))

    state.site.floors.forEach(floor => {
        floor.locations.sort(compare)
    });
  },
  deleteZone (state, zone) {
    let floorIndex = state.site.floors.findIndex(a => a.id === zone.floor_id)
    state.site.floors[floorIndex].zones = state.site.floors[floorIndex].zones.filter(a => a.id !== zone.id)
  },
  updateZone (state, zone) {
    let floorIndex = state.site.floors.findIndex(a => a.id === zone.floor_id)
    Object.assign(state.site.floors[floorIndex].zones[state.site.floors[floorIndex].zones.findIndex(a => a.id === zone.id)], zone)
  },
  createZone (state, zone) {
    let floorIndex = state.site.floors.findIndex(a => a.id === zone.floor_id)
    state.site.floors[floorIndex].zones.push(zone)
  },
  orderZones(state, zoneOrder) {
    const [key, direction] = zoneOrder.split(' ')
    const isAscending = direction === "↑"
    const compare = (a, b) => isAscending ? String(a[key]).localeCompare(String(b[key])) : String(b[key]).localeCompare(String(a[key]))

    state.site.floors.forEach(floor => {
        floor.zones.sort(compare)
    })
  },
};
export default {
  state,
  getters,
  actions,
  mutations
};