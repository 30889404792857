<template>
  <v-card v-if="floor.id" class="fixed-card">

    <v-tabs grow v-model="tab" class="pt-3">
      <v-tab>Floorplan</v-tab>
      <v-tab>
        <v-badge
          color="primary"
          :content="devices.length"
          :value="devices.length"
          bordered
        >
          Devices
        </v-badge>
      </v-tab>
      <v-tab>
        <v-badge
          color="primary"
          :content="attachmentsCount"
          :value="attachmentsCount"
          bordered
        >
          Files & Pictures
        </v-badge>
      </v-tab>
      <v-tab>
        <v-badge
          color="primary"
          :content="secretsCount"
          :value="secretsCount"
          bordered
        >
          Vault
        </v-badge>
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab" class="py-5" min-height="500">
      
      <v-tab-item>    
        <div v-if="floor.svg">
          <v-toolbar
            dense
            floating
            class="floortoolbar"
            v-if="location.id"
          >
            <v-btn icon color="primary" @click="undoEditing" v-if="editing">
              <v-icon>mdi-undo</v-icon>
            </v-btn>
            <v-btn icon color="primary" @click="toggleEditing" v-if="sitePermission>1">
              <v-icon v-if="editing">mdi-close</v-icon>
              <v-icon v-else>mdi-pencil</v-icon>
            </v-btn>
            <v-btn icon color="primary" @click="toggleZoom">
              <v-icon v-if="zooming">mdi-magnify-minus</v-icon>
              <v-icon v-else>mdi-magnify-plus</v-icon>
            </v-btn>
          </v-toolbar>
          <v-toolbar
            floating
            class="floortoolbar"
            v-else-if="sitePermission>1"
          > 
            <v-dialog v-model="deleteDialog" width="600px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="error"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
              <v-card class="text-center py-3">
                <v-icon style="font-size: 64px" color="error" class="my-5">mdi-delete</v-icon>
                <v-card-title>
                  <v-spacer />
                  <div class="text-center">
                    <h3 class="headline error--text mb-0">Delete Floorplan for {{floor.name}}</h3>
                    <h3 class="headline error--text mb-0">Are you Sure ?</h3>
                    <div>Deleted items cannot be retrieved</div>
                  </div>
                  <v-spacer />
                </v-card-title>
                <v-card-actions class="mt-5">
                  <v-spacer />
                  <v-btn @click="deleteDialog = false">Cancel</v-btn>
                  <v-btn @click="deletePlan" color="error">Confirm</v-btn>
                  <v-spacer />
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
          <inline-svg 
            :src="floor.svg"
            id="floorplan"
            @loaded="createFloorplan"
          >
          </inline-svg>
          <div id="locCard"></div>
          <div id="selLocCard"></div>
        </div>
        <div v-else>
          <v-sheet heigth="600" class="text-center mt-12">
            <span class="text-h5">No floorplan Uploaded for this Floor</span> 
            <p class="mt-5 mb-0" v-if="sitePermission>1">
              In order to work properly floorplans need to be of the SVG format (Scalable Vector Graphics).
            </p>
            <p class="mt-2 mb-0" v-if="sitePermission>1">
              Contact Wikibuild support if you need help converting your plans to this Format.   
            </p>
            <p class="my-2" v-if="sitePermission>1">
              Click on the image below to upload your SVG.   
            </p>
            <svg-uploader :floor="floor" directory="floorplans" @located="setFloorSvg" :site_id="site.id" v-if="sitePermission>1"/>
            <div v-if="newSvg">
              <p class="mt-5">
                Great you've selected a suitable format can we proceed to upload this floorplan to Wikibuild ? You can change your file by clicking the image above.  
              </p>
              <v-btn text color="success" class="mt-5" @click="uploadPlan">
                <v-icon left> mdi-upload </v-icon>
                Upload Plan
              </v-btn>
            </div>
          </v-sheet>
        </div>
      </v-tab-item>
      <!-- devices Tab -->
      <v-tab-item>
        <v-toolbar flat>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="searchDev"
            clearable
            flat
            color="primary"
            hide-details
            prepend-inner-icon="mdi-magnify"
            label="Search Devices"
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            dark
            class="mb-2"
            @click="addDevice"
            v-if="sitePermission>1"
          >
            Add Device
          </v-btn>
        </v-toolbar>
        <v-data-table
          :headers="headers"
          :items="devices"
          :search="searchDev"
          :items-per-page="10"
          class="elevation-1"
          @click:row="showDevice"
        >
          <template v-slot:[`item.image`]="{ item }">
            <div class="p-2">
              <v-img v-if="item.image.length > 0" :src="item.image" :alt="item.name" max-height="100px" max-width="100px" contain></v-img>
              <v-img v-else src="https://wtec-uploads.s3.eu-central-1.amazonaws.com/assets/placeholder-image.png" :alt="item.name" max-height="100px" max-width="100px" contain></v-img>
            </div>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <v-chip v-if="item.status" :color="statusColor(item.status)" outlined>
              {{ item.status }}
            </v-chip>
          </template>
        </v-data-table>
      </v-tab-item>
      
      <v-tab-item>
        <attachments view="location"/>
      </v-tab-item>
      <v-tab-item>
        <secrets view="location"/>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import {mapGetters} from 'vuex'
import * as d3 from 'd3'
import InlineSvg from 'vue-inline-svg'
import SvgUploader from '@/components/S3Upload/SvgUploader'
import Attachments from '@/components/Multimodal/Attachments'
import Secrets from '@/components/Multimodal/Secrets'

    
export default {
  name: "LocationCard",
  components: {
    InlineSvg,
    SvgUploader,
    Attachments,
    Secrets
  },
  data() {
    return {
      item: {},
      itemType: "",
      tab: null,
      headers: [
        { text: 'ID', value: 'id', visible: false, class: "success--text subtitle-1" },
        { text: 'Image', value: 'image', visible: true, class: "success--text subtitle-1" },
        { text: 'Product', value: 'name', visible: true, class: "success--text subtitle-1" },
        { text: 'Brand', value: 'brand', visible: true, class: "success--text subtitle-1" },
        { text: 'Code', value: 'code', visible: true, class: "success--text subtitle-1"},
        { text: 'Status', value: 'status', visible: true, class: "success--text subtitle-1" },
      ],
      searchDev: null,
      editing: false,
      zooming: false,
      svg: null,
      initialViewBox: [],
      viewBox: [],
      mouseX: 0,
      mouseY: 0,
      mouseXabs: 0,
      mouseYabs: 0,
      points: [], 
      lastPoint: null,
      startPoint: null,
      polygon: null,
      locCard: null,
      newSvg: null,
      deleteDialog: false,
      fontSize: 10,
    };
  },
  computed: {
    ...mapGetters(['site', 'floor', 'location', 'sitePermission', 'siteDevices', 'tableDevices']),
    devices() {
      if (this.floor.id) {
        if (this.location.id) {
          return this.tableDevices.filter( o => o.location_id == this.location.id && o.archived == false )
        } else {
          return this.tableDevices.filter( o => o.floor_id == this.floor.id && o.archived == false )
        }
      } else {
        return []
      }
    },
    secretsCount () {
      if (this.floor.id) {
        if (this.location.id) {
          return this.site.secrets.filter( o => o.secretable_id == this.location.id && o.secretable_type == 'Location').length
        } else {
          return this.site.secrets.filter( o => o.secretable_id == this.floor.id && o.secretable_type == 'Floor').length
        }
      } else {
        return false
      }
    },
    attachmentsCount () {
      if (this.floor.id) {
        if (this.location.id) {
          return this.site.attachments.filter( o => o.attachable_id == this.location.id && o.attachable_type == 'Location').length
        } else {
          return this.site.attachments.filter( o => o.attachable_id == this.floor.id && o.attachable_type == 'Floor').length
        }
      } else {
        return false
      }
    },
    //all locations with a polygon except the selected location if any
    polyLocations() {
      if (this.floor.id) {
        if (this.location.id) {
          let locations  = this.floor.locations.filter( o => o.polygon )
          return locations.filter(o => o.id !== this.location.id)
        } else {
          return this.floor.locations.filter( o => o.polygon )
        }
      } else {
        return []
      }
    },
    nonPolyLocations() {
      if (this.floor.id) {
        return this.floor.locations.filter( o => o.polygon == null )
      } else {
        return []
      }
    }
  },
  watch: {
    floor(){
      if (this.svg) { this.drawFloor() }
    },
    location() {
      if (this.svg) { this.drawLocation() }
    }
  },
  methods: {
    setFloorSvg (value) {
      this.newSvg = value
    },
    async uploadPlan () {
      const Floor = new FormData()
      Floor.append("id", this.floor.id)
      Floor.append("svg", this.newSvg)
      let response = await this.$http.put('/site/floors', Floor)
      this.$store.commit('updateFloor', response.data.floor)
      this.$toast.success("FloorPlan Uploaded")
      this.newSvg = null
    },
    async deletePlan() {
      const Floor = new FormData()
      Floor.append("id", this.floor.id)
      Floor.append("svg", "")
      let response = await this.$http.put('/site/floors', Floor)
      this.deleteDialog = false
      this.$store.commit('updateFloor', response.data.floor)
      this.$toast.success("FloorPlan Deleted")
    },
    showDevice(item) {
      this.$store.commit('setDevice', item.id)
      setTimeout(() => {
        this.$store.commit('openDeviceDialog')
      }, 100)
    },
    addDevice () {
      this.$store.commit('openCreateDeviceDialog')
    },
    showFloorplan() {
      this.$store.commit('openFloorplanDialog')
    },
    drawFloor() {
      let that = this
      document.querySelectorAll(".location_group").forEach(el => el.remove());
      if (this.floor.locations) {
        let lg = that.svg
          .select('#locations_group')
          .selectAll('g')
          .data(that.polyLocations)
          .enter()
          .append('g')
          .classed("location_group", true)
          .attr("id", function(d) {return "loc_"+d.id})              

        lg.append("polygon")
          .attr("points", function(d) {return d.polygon})
          .attr("id", function(d) { return "zone_poly_"+d.id})
          .attr("fill", "#000000")
          .attr("fill-opacity", "0.3")
          .classed("location_poly", true)
          .on('mouseover', function (d) {
            d3.select(this)
              .transition()
              .duration('50')
              .attr("fill", "#FF0000")
            that.locCard.html(d.target.__data__.name+" || "+d.target.__data__.code)
            that.locCard.transition()
                        .duration(50)
                        .style("opacity", 1)
                        .style("left", (that.mouseXabs+30) + "px")
                        .style("top", (that.mouseYabs-30) + "px")
          })
          .on('mouseout', function (d, i) {
            d3.select(this)
              .transition()
              .duration('50')
              .attr("fill", "#000000")
            that.locCard.transition()
                        .duration(50)
                        .style("opacity", 0);
          })
          .on('click', function (d) {
            that.showItem(d.target.__data__.id)
          })

        /* lg.append("text")
          .text(function(d) {return d.code})
          .attr("x", function(d) {return that.calculateCentroid(d.polygon).x})
          .attr("y", function(d) {return that.calculateCentroid(d.polygon).y})
          .attr("fill", "red")
          .attr("text-anchor", "middle")
          .attr("font-size", that.fontSize)
          .classed("toggleText", true) */
      }    
      this.drawLocation()
    },
    drawLocation() {
      let that = this
      document.querySelectorAll(".location_group_selected").forEach(el => el.remove());
      if (this.location.polygon) {
        that.svg.select('#locations_group')
          .append('g')
          .classed("location_group_selected", true)
          .attr("id", "loc_" + this.location.id)
          .append("polygon")
          .attr("points", this.location.polygon)
          .attr("fill", "#2AAD74")
          .attr('fill-opacity', '0.3')
          .classed("location_poly_selected", true)
        that.svg.select('.location_group_selected')
                .append('text')
                .text(this.location.name + " || " + this.location.code)
                .attr('x', that.viewBoxtoArray(that.findViewBox(that.location.polygon))[0])
                .attr('y', that.viewBoxtoArray(that.findViewBox(that.location.polygon))[1]-5)
      }
    },
    createFloorplan () {
      let that = this
      this.svg = d3.select("#floorplan")
      if (this.svg.attr("viewBox")) {
        this.initialViewBox = this.svg.attr("viewBox").split(" ")
        this.viewBox = this.svg.attr("viewBox").split(" ")
      } else {
        let h = this.svg.attr("height")
        let w = this.svg.attr("width")
        this.initialViewBox = [0, 0, w, h]
        this.viewBox = [0, 0, w, h]
      }
      this.svg.attr("viewBox", this.viewBox.join(" ")).attr("height", "700px").attr("width", "100%")

      this.svg.on('mousemove', function(event) {
        let coords = d3.pointer(event);
        that.mouseX = Math.round(coords[0])
        that.mouseY = Math.round(coords[1])
        let absCoords = d3.pointer(event, "body")
        that.mouseXabs = Math.round(absCoords[0])
        that.mouseYabs = Math.round(absCoords[1])
      });

      this.svg.on('click', function () {
        if (that.editing) {
          document.querySelectorAll(".location_group_selected").forEach(el => el.remove());
          that.lastPoint = that.svg.append("circle")
                                    .attr("cx", that.mouseX)
                                    .attr("cy", that.mouseY)
                                    .attr("r", 5)
                                    .attr("class", "tempdots")
                                    .style("fill", "red")
                                    .style("cursor", 'pointer')

          that.points.push(that.mouseX + ',' + that.mouseY)

          if (!that.startPoint) {
            that.startPoint = that.lastPoint

            that.startPoint.on('click', function () {
              that.polygon = that.points.join(" ");
              that.svg.append("g")
                      .classed("location_group", true)
                      .append("polygon")
                      .attr("points", that.polygon)
                      .attr("fill", "#2AAD74")
                      .attr('fill-opacity', '0.3')
                      .classed("location_poly_selected", true)  
              setTimeout(() => {
                document.querySelectorAll(".tempdots").forEach(el => el.remove());
                that.startPoint = null
                that.lastPoint = null
                that.points = []
              }, 300)
              that.savePolygon()
            });
          };
        }
      })

      this.svg.append("g").attr('id', 'locations_group')
      this.locCard = d3.select("#locCard")
      this.drawFloor()
      this.drawLocation()
    },
    toggleZoom() {
      if (this.location.polygon) {
        if (this.zooming) {
          this.svg.attr("viewBox", this.initialViewBox.join(" "))
          this.zooming = false
        } else {
          let zoomBox = this.findViewBox(this.location.polygon)
          this.svg.attr("viewBox", zoomBox)
          this.zooming = true
        }
      }
    },
    toggleEditing () {
      if (this.editing) {
        this.editing = false
        this.polygon = null
        this.startPoint = null
        this.lastPoint = null
        this.points = []
        document.querySelectorAll(".tempdots").forEach(el => el.remove())
        this.drawLocation()
      } else {
        this.editing = true
      }
    },
    undoEditing () {
      let tempdots = document.querySelectorAll('.tempdots')
      tempdots[tempdots.length-1].remove()
      this.points.pop()
      if (this.points.length == 0) {this.startPoint = null}
    },
    findViewBox(str) {
      let arr = str.split(/[ ,]+/);
      let arrX = [];
      let arrY = [];
      for (let i = 0; i < arr.length; i++) {
        if ((i + 2) % 2 == 0) {
          arrX.push(parseInt(arr[i]));
        }
        else {
          arrY.push(parseInt(arr[i]));
        }
      }
      let xMin = Math.min(...arrX);
      let yMin = Math.min(...arrY);
      let xMax = Math.max(...arrX);
      let yMax = Math.max(...arrY);
      let width = xMax - xMin;
      let height = yMax - yMin;
      let viewBox = xMin + " " + yMin + " " + width + " " + height;
      return viewBox;
    },
    viewBoxtoArray(str) {
      let arr = str.split(" ")
      return [arr[0], arr[1]]
    },
    /* calculateCentroid(cord) {
      let points = cord.split(' ')
                       .map(point => point.split(',').map(Number))
      points.push(points[0])
      let area = 0, C_x = 0, C_y = 0;
        
        for (let i = 0; i < points.length - 1; i++) {
            const x0 = points[i][0], y0 = points[i][1];
            const x1 = points[i + 1][0], y1 = points[i + 1][1];

            const cross = (x0 * y1) - (x1 * y0);
            area += cross;
            C_x += (x0 + x1) * cross;
            C_y += (y0 + y1) * cross;
        }

        area *= 0.5;
        C_x = (1 / (6 * area)) * C_x;
        C_y = (1 / (6 * area)) * C_y;

        return { x: C_x, y: C_y }
    },
    changeFontSize () {
      const textElements = document.querySelectorAll('.toggleText');
        
      // Loop through the selected elements and change their font size
      textElements.forEach((element) => {
          element.setAttribute('font-size', this.fontSize)
      });
    }, */
    async savePolygon() {
      const Location = new FormData();
      Location.append("polygon", this.polygon);
      Location.append("id", this.location.id)
      let response = await this.$http.put('/site/locations', Location)
      this.$store.commit('updateLocation', response.data.location)
      this.$store.commit('setLocation', response.data.location)
      this.$toast.success("Location Updated")
      this.editing = false
      this.polygon = null
      this.drawFloor()
      this.drawLocation()
    },
    showItem(id) {
      if (!this.editing) { this.$emit('newLocation', id) } 
    },
    statusColor(status) {
      let index = this.site.device_statuses.findIndex(a => a.name == status)
      return (index > -1) ? this.site.device_statuses[index].color : "#F1F1F1"
    },
  },
  mounted() {
    this.$store.commit('setHeaderTitle', this.site.name + ' > Locations')
    this.$store.commit('unsetFloorLocationZone')
  },
};
</script>

<style lang="scss" scoped>
  .floortoolbar {
    position: absolute;
    right: 15px;
    top: 15px;
  }

  #locCard,
  #selLocCard {
     position: fixed;
     text-align: center;
     padding: .5rem;
     background: #FFFFFF;
     border: 1px solid #000; 
     opacity: 0;
  }

  .fixed-card {
    position: sticky;
    top: 64px;
  }

</style>