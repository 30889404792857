export default {
  methods: {
    // Add a global method
    $showUserRole (role) {
      if (role === 5) {
        return "Super Admin"
      } else if (role === 4) {
        return "Library Admin"
      } else if (role === 3) {
        return "Integrator"
      } else if (role === 2) {
        return "Building Owner"
      } else if (role === 1) {
        return "User"
      } else {
        return "no role assigned"
      }
    },
    
    $showSiteRole (site_access) {
      if (site_access) {
        if (site_access.active) {
          if (site_access.user_role == 1) {
            return "Reader"
          } else if (site_access.user_role == 2) {
            return "Contributor"
          } else if (site_access.user_role == 3) {
            return "Full Access"
          }
        } else {
          return "Access Expired"
        }
      } else {
        return "No Access"
      }
    },

    $capitalize(val) {
      return String(val).charAt(0).toUpperCase() + String(val).slice(1);
    }
  }
}