<template>
  <div>
    <v-list-item @click="dialog = true">
      <v-list-item-icon>
        <v-icon>mdi-playlist-edit</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        Edit Selected Devices
      </v-list-item-content>
    </v-list-item>
    <v-dialog v-model="dialog" max-width="900">
      <v-card>
        <v-toolbar class="elevation-2" color="grey lighten-3 mb-5">
          <v-toolbar-title class="headline primary--text">Bulk edit {{ bulkEditableDevices.length }} Devices</v-toolbar-title>
          <v-spacer></v-spacer>
          <toolbar-icon-button tooltip="Save" icon="mdi-content-save" color="primary" @click="updateDevices" />
          <toolbar-icon-button tooltip="Close" icon="mdi-close" color="error" @click="closeDialog" />
        </v-toolbar>
        <v-card-text> 
          <v-subheader>Leave blank to keep current values</v-subheader>      
          <v-row >
            <v-col cols="6" class="pr-5">
              <v-autocomplete v-model="actions.status.value" :label="actions.status.text" :items="site.device_statuses" item-text="name" item-value="name" clearable chips />
              <v-autocomplete v-model="actions.floor.value" :label="actions.floor.text" :items="site.floors" item-text="name" item-value="id" clearable chips />
              <v-autocomplete v-model="actions.location.value" :label="actions.location.text" :items="locationsOnFloor" item-text="name" item-value="id" clearable chips />
              <v-autocomplete v-model="actions.contractor.value" :label="actions.contractor.text" :items="siteContractors" item-text="name" item-value="id" clearable chips />
              <date-picker-menu v-model="actions.purchase_date.value" :label="actions.purchase_date.text" icon="" />
            </v-col>
            <v-col cols="6" class="pl-5">
              <v-autocomplete v-model="actions.archived.value" :label="actions.archived.text" :items="[true, false]" clearable chips />
              <v-autocomplete v-model="actions.zone.value" :label="actions.zone.text" :items="zonesOnFloor" item-text="name" item-value="id" clearable chips />
              <v-autocomplete v-model="actions.layer.value" :label="actions.layer.text" :items="site.layers" item-text="name" item-value="id" clearable chips />
              <v-autocomplete v-model="actions.supplier.value" :label="actions.supplier.text" :items="siteSuppliers" item-text="name" item-value="id" clearable chips />
              <date-picker-menu v-model="actions.warranty_expiration_date.value" :label="actions.warranty_expiration_date.text" icon="" />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-textarea
                outlined
                label="Note"
                v-model="actions.note.value"
              ></v-textarea>
            </v-col>
          </v-row>        
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: "DeviceBulkEdit",
  data() {
    return {
      dialog: false,
      actions: {
        status:                     {icon: "mdi-list-status", text: "Status", value: null},
        archived:                   {icon: "mdi-archive", text: "Archived", value: null},
        floor:                      {icon: "mdi-layers-triple", text: "Floor", value: null},
        location:                   {icon: "mdi-table", text: "Location", value: null},
        zone:                       {icon: "mdi-table", text: "Zone", value: null},
        layer:                      {icon: "mdi-layers-triple", text: "Layer", value: null},
        supplier:                   {icon: "mdi-package-variant", text: "Supplier", value: null},
        contractor:                 {icon: "mdi-tools", text: "Contractor", value: null},
        purchase_date:              {icon: "mdi-calendar-edit", text: "Purchase Date", value: null},
        warranty_expiration_date:   {icon: "mdi-calendar-alert", text: "Warranty Expires", value: null},
        note:                       {icon: "mdi-note-edit", text: "Note", value: null},
      },
    }
  },
  props: ['devices'],
  computed: {
    ...mapGetters(['site', 'deviceFilters', 'filteredDevices', 'companies']),
    locationsOnFloor () {
      if (this.actions.floor.value > 0) {
        let floorIndex = this.site.floors.findIndex(a => a.id == this.actions.floor.value)
        return this.site.floors[floorIndex].locations
      } else {
        return []
      }
    },
    zonesOnFloor () {
      if (this.actions.floor.value > 0) {
        let floorIndex = this.site.floors.findIndex(a => a.id == this.actions.floor.value)
        return this.site.floors[floorIndex].zones
      } else {
        return []
      }
    },
    siteContractors () {
      return this.companies.filter(company => this.site.contractors.includes(company.id))
    },
    siteSuppliers () {
      return this.companies.filter(company => company.company_type === 'Supplier');
    },
    bulkEditableDevices () {
      let devices = []
      if (this.devices.length === 0) {
        devices = this.filteredDevices
      } else {
        devices = this.devices 
      }
      return devices
    },
  },
  methods: {
    async updateDevices() {
      const errors = []
      for (const dev of this.bulkEditableDevices) {
        const Device = new FormData()
        Device.append('id', dev.id)
        if (this.actions.floor.value != null) { Device.append('floor_id', this.actions.floor.value) }
        if (this.actions.location.value != null) { Device.append('location_id', this.actions.location.value) }
        if (this.actions.zone.value != null) { Device.append('zone_id', this.actions.zone.value) }
        if (this.actions.layer.value != null) { Device.append('layer_id', this.actions.layer.value) }
        if (this.actions.note.value != null) { Device.append('note', this.actions.note.value) }
        if (this.actions.archived.value != null) { Device.append('archived', this.actions.archived.value) }
        if (this.actions.status.value != null) { Device.append('status', this.actions.status.value) }
        if (this.actions.contractor.value != null) { Device.append('contractor_id', this.actions.contractor.value) }
        if (this.actions.supplier.value != null) { Device.append('supplier_id', this.actions.supplier.value) }
        if (this.actions.purchase_date.value != null) { Device.append('purchase_date', this.actions.purchase_date.value) }
        if (this.actions.warranty_expiration_date.value != null) { Device.append('warranty_expiration_date', this.actions.warranty_expiration_date.value) }

        try {
          const response = await this.$http.put('/site/devices', Device)
          if (response.data.error) {
            if (Array.isArray(response.data.error)) {
              response.data.error.forEach(m => errors.push(m))
            } else {
              errors.push(response.data.error)
            }
          }
        } catch (error) {
          errors.push('Error updating device')
        }
      }

      if (errors.length > 0) {
        errors.forEach(m => this.$toast.error(m))
      } else {
        await this.$store.dispatch('refreshDevices', this.site.id)
        this.$toast.success('All Devices Updated successfully')
        this.closeDialog()
      }
    },
    closeDialog () {
      this.dialog = false
      for (let key in this.actions) {
        if (this.actions.hasOwnProperty(key)) {
          this.actions[key].value = null
        }
      }
    }
  },
};
</script>