<template>
  <v-container fluid class="products-page mt-5">
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="tableProducts"
          :search="search"
          :loading="loading"
          sort-by="id"
          class="elevation-1"
          fixed-header
          height="72vh"
          @click:row="editItem"
        >
          <template v-slot:top>
            <v-toolbar flat height="90">
              <!-- no menu when not admin -->
              <v-spacer></v-spacer>
              <table-search-field v-model="search"></table-search-field>
              <v-spacer></v-spacer>

              <create-product @done="initialize" :tenants="tenants" :procats="procats"/>
              <update-product @done="initialize" :item="item" v-model="dialogVisible" :tenants="tenants" :procats="procats"/>

            </v-toolbar>
          </template>
          <template v-slot:[`item.image`]="{ item }">
            <div class="p-2">
              <v-img :src="item.image" :alt="item.id" max-height="80px" max-width="100px" contain></v-img>
            </div>
          </template>
          <template v-slot:[`item.created_at`]="{ item }">
            {{ item.created_at | moment($store.getters.dateFormat) }}
          </template>
          <template v-slot:[`item.updated_at`]="{ item }">
            {{ item.updated_at | moment($store.getters.dateFormat) }}
          </template>
          <template v-slot:no-data>
            <v-btn color="primary" @click="initialize">Reset</v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import CreateProduct from '@/components/Forms/Product/CreateProduct'
  import UpdateProduct from '@/components/Forms/Product/UpdateProduct'
  import XlsProductsDownloader from '@/components/Xls/XlsProductsDownloader'
  import XlsProductsUploader from '@/components/Xls/XlsProductsUploader'

  export default {
    name: 'Products',
    components: {
      XlsProductsDownloader,
      XlsProductsUploader,
      CreateProduct,
      UpdateProduct,
    },
    data: () => ({
      products: [],
      tenants: [],
      procats: [],
      companies: [],
      menu: false,
      search: '',
      loading: true,
      dialogVisible: false,
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Image', value: 'image', sortable: false },
        { text: 'Brand', value: 'brand' },
        { text: 'Model', value: 'product_model' },
        { text: 'Description', value: 'name' },
        { text: 'Category', value: 'procat' },
        { text: 'Tenant', value: 'tenant' },
        { text: 'Created at', value: 'created_at' },
        { text: 'Updated at', value: 'updated_at' },
      ],
      item: {},
    }),

    created () {
      this.initialize()
      this.$store.commit('setHeaderTitle', 'Products')
    },

    computed: {
      tableProducts() {
        return this.products.map(product => {
          return {
            id: product.id,
            image: product.image,
            brand_id: product.brand_id,
            product_model: product.product_model,
            name: product.name,
            procat_id: product.procat_id,
            tenant_id: product.tenant_id,
            created_at: product.created_at,
            updated_at: product.updated_at,
            procat: product.procat_id ? this.procats.find(obj => obj.id === product.procat_id).name : "unknown",
            brand: product.brand_id ? this.companies.find(obj => obj.id === product.brand_id).name : "unknown",
            tenant: product.tenant_id ? this.tenants.find(obj => obj.id === product.tenant_id).name : "unknown",
          }
        })
      },
    },

    methods: {
      async initialize () {
        let response = await this.$http.get('/tenant/products')
        this.products = response.data.products
        this.tenants = response.data.tenants
        this.procats = response.data.procats
        this.companies = response.data.companies
        this.loading = false
      },

      editItem (item) {
        this.dialogVisible = true
        this.item = Object.assign({}, item)
      },
      
      showName(id, type) {
        const data = this[type]
        if (!data) return 'Unknown'

        const item = data.find(obj => obj.id === id)
        return item ? item.name : 'Unknown'
      },

    },
  }
</script>