<template>
  <v-container fluid class="admin-page">
    <h1 class="page-title mt-10 mb-6">Release Notes</h1>
    <h3>Wikibuild 0.1.6</h3>
    <ul class="mb-12">
      <li>inputs in device card are fully clickable with stop propagation on other controls</li>
      <li>Devices in the location card are viewable again bugfix</li>
      <li>Show archived DEvices toggle changed for more clarity</li>
      <li>device filter count is visiblle again in green badge instead of red</li>
      <li>Brand is shown in the devicecard before model on second line</li>
      <li>Mobile version second iteration</li>
      <li>Mobile Sites with create</li>
      <li>Mobile Dashboard, access, logs, attachments </li>
      <li>Mobile Devices, create, view and update, update product, create product, add attachment, add secret and Activity log</li>
      <li>same routes and pages for all mobile urls mobile toggle in header menu</li>
      <li>Mobile profile page</li>
      <li>Userfilter inUserselect doesn't throw error when there is no fname or lname</li>
      <li>remove double pipes when code is not available in select inputs</li>
      <li>change tabs in monbile device card</li>
    </ul>
    <h3>Wikibuild 0.1.5</h3>
    <ul class="mb-12">
      <li>new design for Device Card with status selector, archive and location selectors</li>
      <li>Archive Toggle is moved inside the filter menu and inside the dot menu in device card</li>
      <li>Add Device button in devices is now add Product</li>
      <li>All code concerning site_shares have been removed from frontend and backend</li>
      <li>Contractors are now selectable in Site Config page</li>
      <li>All references to company_id have been removed from backend and rfontend</li>
      <li>Bulk Edit with Contractor and Supplier</li>
      <li>Download and Upload from Excel with Contractor and Supplier</li>
      <li>Filter with Contractor and Supplier</li>
      <li>limit site logs to 500</li>
      <li>Secrets are assigned to a user when created and this user can flag a secret as private or shared</li>
      <li>Users can see and edit only shared secrets or private secrets that belong to them</li>
      <li>Couldn't create zones from locations page bug fixed</li>
      <li>Couldn't create site accesses for registred users bug fixed</li>
      <li>Login as invalid Master password bug fixed</li>
      <li>Complete refactoring of Vuex store for Sites</li>
      <li>Animation between pages for smoother axperience</li>
      <li>Start of AI generated Product</li>
      <li>Table and Grid views for devices</li>
    </ul>
    <h3>Wikibuild 0.1.4</h3>
    <ul class="mb-12">
      <li>Start of mobile layout</li>
      <li>New Device card with inline editing for text fields, date pickers and textarea</li>
      <li>Removal of update Device Form</li>
      <li>Loading screen when Site is fetching info</li>
      <li>Device modal can be closed with a click outside</li>
      <li>Device modal stays open and switches device whet another device is clicked in the device list</li>
    </ul>
    <h3>Wikibuild 0.1.3</h3>
    <ul>
      <li>Release Notes page in Administration</li>
      <li>In Site/Devices show total devices without archived if not showing</li>
      <li>Brand for devices is now a relation to a company and not and editable field</li>
      <li>Add global components ToolbarIcon, DatePickerMenu and ToolbarSearch</li>
      <li>Start on the DeviceCard separated from the device form</li>
      <li>Start on the UpdateDevice form</li>
      <li>CreateCompany and UpdateCompany are modified to be OK with new Database structure, companies table is not yet visible for users only in administration</li>
      <li>Import and export Companies in Administration</li>
      <li>CompanySelectandCreate that allows selection or creation on the fly for all companies Customers, Brands, Suppliers and contractors</li>
      <li>attachments, vault and logs are modified to work well in the DeviceCard</li>
      <li>S3Attacher had a little bug on computed properties and was corrected</li>
    </ul>
  </v-container>
</template>

<script>
  
  export default {
    name: 'ReleaseNotes',
    data () {
      return {
        loading: false,
      }
    },
    mounted() {
    },
    methods: {
    }
  }
</script>