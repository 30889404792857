<template>
  <v-dialog v-model="dialog" max-width="800px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon class="mb-2 mr-2" :block="block" v-bind="attrs" v-on="on"><v-icon>mdi-auto-fix</v-icon></v-btn>
    </template>
    <v-card>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="9" class="mt-8">
              <v-text-field v-model="prompt" label="Generate Product" prepend-icon="mdi-package-variant"></v-text-field>
            </v-col>
            <v-col cols="3" class="mt-8 d-flex align-center">
              <v-btn color="primary" @click="callGemini"><v-icon dark>mdi-brain</v-icon></v-btn>
              <v-btn color="error" @click="close" class="ml-4"><v-icon dark>mdi-close</v-icon></v-btn>
            </v-col>
            <v-col cols="12" v-if="formattedProduct">
              <pre class="wrapped">{{ formattedProduct }}</pre>
            </v-col>
            
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  import {mapGetters} from 'vuex'

  export default {
    name: 'AiCreateProduct',
    props: {
      admin: { type: Boolean, default: false },
      procats: { type: Array, default: () => [] },
      block: { type: Boolean, default: false }, 
    },
    data() {
      return {
        prompt: "",
        dialog: false,
        product: {}
      }
    },
    computed: {
      ...mapGetters(['defaultProduct', 'createProductSystemInstructions']),
      formattedProduct() {
        return JSON.stringify(this.product, null, 2)
      }
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
    },

    created () {
      this.initialize()
    },

    methods: {
      async initialize () {
      },

      close () {
        this.dialog = false
      },

      async save () {
        const productData = {}
        const fields = [
          'name', 'image', 'product_model', 'product_url', 'product_status', 'power', 'sku', 'upc', 'ean', 
          'description', 'features', 'height', 'width', 'depth', 'weight', 'rack_mounted', 'rack_units', 
          'brand_id', 'procat_id', 'tenant_id',
        ]

        fields.forEach(field => {
          if (this.editedItem[field] !== undefined && this.editedItem[field] !== null && this.editedItem[field] !== "") {
            productData[field] = this.editedItem[field]
          }
        })
         
        try {
          let url = this.admin ? '/admin/products' : '/tenant/products'
          let response = await this.$http.post(url, productData)
          if (response.data.error) {
            Array.isArray(response.data.error) ? response.data.error.forEach(m => this.$toast.error(m)) : this.$toast.error(response.data.error)
          } else {
            this.$emit('done', response.data.product)
            this.$toast.success("Product Created")
            this.$refs.imgUploader.clear()
            this.close()
          }
        } catch (error) {
          this.$toast.error("Error Creating product: "+ error)
        }
      },
      async callGemini () {
        const { GoogleGenerativeAI } = require("@google/generative-ai");

        const genAI = new GoogleGenerativeAI("AIzaSyAbuD8dqAP-URkLjuvPCu3ImFPZjQPdv-M");
        const model = genAI.getGenerativeModel({  model: "gemini-1.5-flash",
                                                  systemInstruction: this.createProductSystemInstructions     
                                              })

        const prompt = this.prompt
                                              
        const result = await model.generateContent(prompt)
        let text = result.response.text()
        const start = text.indexOf('{');
        const end = text.lastIndexOf('}') + 1;
        if (start !== -1 && end !== -1) {
          text = text.substring(start, end);
          this.product = JSON.parse(text);
        } else {
          console.error('Invalid JSON format');
        }
        console.log(text)
      },

    },
  }
</script>

<style scoped>
  .wrapped {
    white-space: pre-wrap; /* Ensures the JSON is wrapped properly */
    word-wrap: break-word; /* Ensures long words are wrapped */
    overflow-wrap: break-word; /* Ensures long words are wrapped */
  }
</style>