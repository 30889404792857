<template>
  <v-menu
    v-model="editing"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-hover v-slot="{ hover }" open-delay="200">
        <v-card flat :color="hover ? '#EFEFEF' : 'white'">
          <v-list-item @click="toggleEditing" class="cpointer">
            <v-list-item-avatar>
              <v-icon v-if="editing" color="primary">mdi-pencil</v-icon>
              <v-icon v-else>{{icon}}</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-subtitle class="primary--text">{{text}}</v-list-item-subtitle>
              
              <v-list-item-title>
                <div v-bind="attrs" v-on="on" class="cpointer">{{ computedDate }}</div>
              </v-list-item-title>
            </v-list-item-content>

            <v-list-item-action :class="{ 'd-none': !hover }" class="my-1">
              <div>
                <v-btn icon>
                  <v-icon color="error" small @click.stop="clear">mdi-close</v-icon>
                </v-btn>
                <v-btn icon v-if="editing">
                  <v-icon color="primary" small >mdi-content-save</v-icon>
                </v-btn>
                <v-btn icon v-else>
                  <v-icon color="primary" small @click.stop="toggleEditing">mdi-pencil</v-icon>
                </v-btn>
                <v-btn icon>
                  <v-icon color="info" small @click.stop="copyContent">mdi-content-copy</v-icon>
                </v-btn>
              </div>
            </v-list-item-action>
          </v-list-item>
        </v-card>
      </v-hover>
    </template>
    <v-date-picker
      v-model="localValue"
      @input="emitValue"
    ></v-date-picker>
  </v-menu>
</template>

<script>
export default {
  name: 'InlineDatePicker',
  props: {
    value: { type: String, default: ''},
    icon: { type: String, default: 'mdi-calendar'},
    text: { type: String, default: 'Select Date'},
    persistent_hint: { type: Boolean, default: false},
    hint: { type: String, default: ''},
    model_prop: { type: String, required: true },
    model_type: { type: String, required: true },
    model_id: { type: Number, required: true },
  },
  data() {
    return {
      localValue: this.value,
      editing: false,
    };
  },
  computed: {
    computedDate() {
      return this.localValue ? this.$moment(String(this.localValue)).format('DD/MM/YYYY') : ''
    },
    url() {
      if (this.model_type === 'device') {
        return '/site/devices'
      } else if (this.model_type === 'product') {
        return '/products'
      }
    },
    mutation() { 
      if (this.model_type === 'device') {
        return 'updateDevice'
      } else if (this.model_type === 'product') {
        return 'updateProduct'
      }
    }
  },
  watch: {
    value(newValue) {
      this.localValue = newValue
    }
  },
  methods: {
    async emitValue() {
      if (this.localValue === this.value) {
        this.editing = false
      } else { 
        const item = {
          id: this.model_id,
          [this.model_prop]: this.localValue
        }
        try {
          let response = await this.$http.put(this.url, {...item})
          this.$store.commit(this.mutation, response.data[this.model_type])
          this.$toast.success( this.$capitalize(this.model_type) + " Updated")
        } catch (error) {
          this.$toast.error('Failed to update')
          console.error(error)
        } finally {
          this.editing = false
        }        
      }
    },
    clear() {
      this.localValue = ''
      this.emitValue()
    },
    toggleEditing() {
      this.editing = !this.editing
    },
    copyContent() {
      navigator.clipboard.writeText(this.computedDate).then(() => {
        this.$toast.success('Copied to clipboard')
      }).catch(err => {
        this.$toast.error('Failed to copy')
      });
    },
  }
};
</script>