import Vue from 'vue'
import App from './App.vue'
import router from './Routes'
import store from './store/index'
import vuetify from './plugins/vuetify'
import GlobalFunctions from './mixins/globalFunctions'
import Toast, { POSITION } from "vue-toastification"
import "vue-toastification/dist/index.css"
import axios from 'axios'
import axiosConfig from './axios/config'
import VueExcelXlsx from "vue-excel-xlsx"
import './plugins/global-components'

const options = {
  position: POSITION.BOTTOM_CENTER,
  timeout: 2000,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: true,
  hideProgressBar: false,
  closeButton: "button",
  icon: true
}

Vue.use(Toast, options)

Vue.prototype.$http = axios
axios.defaults.withCredentials = false
if (process.env.NODE_ENV == "production") {
  axios.defaults.baseURL = 'https://wtools-api.herokuapp.com/api/v1'
} else {
  axios.defaults.baseURL = 'http://localhost:3000/api/v1'
}
axiosConfig()

Vue.use(require('vue-moment'))

Vue.config.productionTip = false

Vue.use(VueExcelXlsx)
Vue.mixin(GlobalFunctions)

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')