<template>
  <v-dialog
    v-model="dialogVisible"
    fullscreen
    transition="custom-slide-x-reverse-transition"
    :content-class="isMobile ? '' : 'right-sheet'"
    @keydown.esc="close" 
    @click:outside="close"
  >
    <v-card>
      <v-toolbar flat dense>
        <toolbar-icon-button tooltip="Close Device" icon="mdi-arrow-right" color="grey" @click="close" tooltipLocation="bottom"/>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card class="cpointer mx-2" @click="openProduct" outlined>
        <div class="float-right">
          <toolbar-icon-button tooltip="Open Product" icon="mdi-open-in-new" color="grey" @click="openProduct" tooltipLocation="top" />
        </div>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="3">
              <v-img v-if="device.product?.image" height="150" :src="device.product.image" contain></v-img>
            </v-col>
            <v-col cols="12" md="9" class="pl-8">
              <h4 class="mb-5">{{device.product?.name}}</h4>
              <h1 class="card-title mb-5">{{showBrand(device.product?.brand_id)}} {{device.product?.product_model}}</h1>
              <h6 class="primary--text">{{device.product?.procat?.name}}</h6> 
            </v-col>
          </v-row>
        </v-card-text>
        <update-product @done="refresh" :item="device.product" v-model="productDialog" :procats="procats"/>
      </v-card>
      <inline-text-input 
        v-if="isMobile"
        v-model="dev.code" 
        model_prop="code" 
        text="Device Code" 
        icon="mdi-xml" 
        :model_id="dev.id" 
        model_type="device"
        class="mt-3"/>
      <v-toolbar height="80" flat>
        <inline-text-input 
          v-if="!isMobile"
          v-model="dev.code" 
          model_prop="code" 
          text="Device Code" 
          icon="mdi-xml" 
          :model_id="dev.id" 
          model_type="device"/>
        <v-spacer v-if="!isMobile"></v-spacer>
        <status-selector></status-selector>
        <v-spacer v-if="isMobile"></v-spacer>
        <v-menu offset-y left >
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item v-if="sitePermission>1" @click="toggleArchive()">
              <v-list-item-icon>
                <v-icon v-if="device.archived" color="error">mdi-archive-cancel-outline</v-icon>
                <v-icon v-else color="primary">mdi-archive-check-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-if="device.archived">Remove Device from Archive</v-list-item-title>
                <v-list-item-title v-else>Archive this Device</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar>
      
      <v-tabs v-if="!isMobile" v-model="tab" fixed grow>
        <v-tab>General</v-tab>
        <v-tab>Files & Pictures</v-tab>
        <v-tab>Vault</v-tab>
        <v-tab @click="reloadLogs()">Activity</v-tab>
      </v-tabs>
      <v-tabs v-if="isMobile" v-model="tab" fixed grow>
        <v-tab><v-icon>mdi-phone</v-icon></v-tab>
        <v-tab><v-icon>mdi-paperclip</v-icon></v-tab>
        <v-tab><v-icon>mdi-shield-lock</v-icon></v-tab>
        <v-tab @click="reloadLogs()"><v-icon>mdi-clipboard-text-clock</v-icon></v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab" class="mt-6">
        <v-tab-item>
          <v-virtual-scroll :items="[1]" item-height="515" height="515">
            <v-row class="mx-0 pt-2">
              <v-col cols="12" md="6" class="py-0">
                <inline-select-input  
                  v-model="dev.floor_id" 
                  model_prop="floor_id" 
                  text="Floor" 
                  icon="mdi-layers-triple-outline"
                  :items="site.floors"
                  :model_id="dev.id" 
                  model_type="device"/>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <inline-select-input  
                  v-model="dev.location_id" 
                  model_prop="location_id" 
                  text="Location" 
                  icon="mdi-map-marker"
                  :items="locationsOnFloor"
                  :model_id="dev.id" 
                  model_type="device"/>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <inline-select-input  
                  v-model="dev.zone_id" 
                  model_prop="zone_id" 
                  text="Zone" 
                  icon="mdi-table"
                  :items="zonesOnFloor"
                  :model_id="dev.id" 
                  model_type="device"/>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <inline-select-input  
                  v-model="dev.layer_id" 
                  model_prop="layer_id" 
                  text="Layer" 
                  icon="mdi-layers-triple"
                  :items="site.layers"
                  :model_id="dev.id" 
                  model_type="device"/>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <inline-select-input  
                  v-model="dev.supplier_id" 
                  model_prop="supplier_id" 
                  text="Supplier" 
                  icon="mdi-package-variant"
                  :items="suppliers"
                  :model_id="dev.id" 
                  model_type="device"/>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <inline-select-input  
                  v-model="dev.contractor_id" 
                  model_prop="contractor_id" 
                  text="Contractor" 
                  icon="mdi-tools"
                  :items="contractors"
                  :model_id="dev.id" 
                  model_type="device"/>
              </v-col>
            </v-row>
            <v-row class="mx-0">
              <v-col cols="12">
                <v-divider></v-divider>
              </v-col>
            </v-row>
            <v-row class="mx-0">
              <v-col v-for="item in filteredProperties" :key='item.property' cols="12" md="6" class="py-0">
                <inline-text-input 
                  v-if="item.inputType === 'text'" 
                  v-model="dev[item.property]" 
                  :model_prop="item.property" 
                  :text="item.text" 
                  :icon="item.icon" 
                  :model_id="dev.id" 
                  model_type="device"/>
                <inline-date-picker 
                  v-if="item.inputType === 'date'" 
                  v-model="dev[item.property]" 
                  :model_prop="item.property" 
                  :text="item.text" 
                  :icon="item.icon" 
                  :model_id="dev.id" 
                  model_type="device"/>
                <inline-select-input 
                  v-if="item.inputType === 'select'" 
                  v-model="dev[item.property]" 
                  :model_prop="item.property" 
                  :text="item.text" 
                  :icon="item.icon"
                  :items="site[item.items]"
                  :model_id="dev.id" 
                  model_type="device"/>
              </v-col>
            </v-row>
            
            <v-row v-if="getPropertyValue('note').length > 0 || showAllProperties" class="mx-0">
              <v-col cols="12">
                <inline-text-area v-model="dev.note" model_prop="note" text="Note" icon="mdi-note-outline" :model_id="dev.id" model_type="device" />
              </v-col>
            </v-row>
            <v-row class="mx-3">
              <v-col cols="12">
                <v-btn v-if="showAllProperties" text @click="showAllProperties = !showAllProperties">
                  <v-icon class="mr-3">mdi-minus</v-icon>
                  Show Less properties
                </v-btn>
                <v-btn v-else text @click="showAllProperties = !showAllProperties">
                  <v-icon class="mr-3">mdi-plus</v-icon>
                  Show More properties
                </v-btn>
              </v-col>
            </v-row>
          </v-virtual-scroll>
        </v-tab-item>
        <v-tab-item>
          <attachments view="device" class="px-3"/>
        </v-tab-item>
        <v-tab-item>
          <secrets view="device"/>
        </v-tab-item>
        <v-tab-item>
          <logs view="device"/>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import StatusSelector from '@/components/Selectors/StatusSelector.vue'
import Attachments from '@/components/Multimodal/Attachments'
import Secrets from '@/components/Multimodal/Secrets'
import Logs from '@/components/Multimodal/Logs'
import UpdateProduct from '@/components/Forms/Product/UpdateProduct'

export default {
  name: 'DeviceCard',
  components: {
      StatusSelector,
      Attachments,
      Secrets,
      Logs,
      UpdateProduct,
    },
  props: {
    value: { type: Boolean, required: true },
  },
  data() {
    return {
      localValue: false,
      nav: 'recent',
      dev: {},
      tab: 0,
      properties: [
        {icon: "mdi-barcode", text: "Serial Number", property: 'sn', inputType: 'text'  },
        {icon: "mdi-chip", text: "Mac Adress", property: 'mac', inputType: 'text'  },
        {icon: "mdi-lan", text: "IP address", property: 'ip', inputType: 'text' },
        {icon: "mdi-ethernet", text: "Patch", property: 'patch', inputType: 'text' },
        {icon: "mdi-server", text: "Port", property: 'port', inputType: 'text' },
        {icon: "mdi-calendar-edit", text: "Purchase Date", property: 'purchase_date', inputType: 'date' },
        {icon: "mdi-calendar-alert", text: "Warranty Expires", property: 'warranty_expiration_date', inputType: 'date' },
      ],
      statusMenu: false,
      productDialog: false,
      deviceDialog: false,
      showAllProperties: false,
    }
  },

  computed: {
    ...mapGetters(['site', 'device', 'sitePermission', 'procats', 'contractors', 'suppliers', 'companies', 'isMobile']),
    dialogVisible: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    activeTab() {
      switch (this.tab) {
        case 0:
          return 'General'
        case 1:
          return 'Files & Pictures'
        case 2:
          return 'Vault'
        case 3:
          return 'Activity'
      }
    },
    filteredProperties() {
      if (this.showAllProperties) {
        return this.properties
      } else {
        return this.properties.filter(item => this.getPropertyValue(item.property).length > 0)
      }
    },
    locationsOnFloor () {
      if (this.dev.floor_id) {
        let floorIndex = this.site.floors.findIndex(a => a.id == this.dev.floor_id)
        return this.site.floors[floorIndex].locations
      } else {
        return []
      }
    },
    zonesOnFloor () {
      if (this.dev.floor_id) {
        let floorIndex = this.site.floors.findIndex(a => a.id == this.dev.floor_id)
        return this.site.floors[floorIndex].zones
      } else {
        return []
      }
    },
  },

  watch: {
    dialogVisible(newVal) {
      newVal ? this.reload() : this.close()
    },
    device(){
      this.reload()
    }
  },
  mounted () {
    this.reload()
  },
  methods: {
    close() {
      this.$store.commit('closeDeviceDialog')
    },
    reload() {
      this.dev = this.device
    },
    async refresh() {
      await this.$store.dispatch('refreshDevices', this.site.id)
      await this.$store.dispatch('refreshProducts', this.site.id)
      this.$nextTick(() => {
        this.$store.commit('setDevice', this.dev.id)
        this.reload()
      })
      
    },
    openProduct() {
      this.productDialog = true
    },
    getPropertyValue(property) {
      if (property === 'purchase_date' || property === 'warranty_expiration_date') {
        return this.dev[property] ? this.$options.filters.moment(this.dev[property], this.$store.getters.dateFormat) : ''
      }
      return this.dev[property] || ''
    },

    showBrand(id) {
      if (id) {
        let brand = this.companies.find(c => c.id == id)
        return brand ? brand.name : ''
      }
      return ''
    },


    async reloadLogs() {
      const Device = new FormData()
      Device.append("model", "devices")
      Device.append("id", this.device.id)
      let response = await this.$http.post('/site/devices/logs', Device)
      this.$store.commit('setDeviceLogs', response.data.logs)
    },

    async toggleArchive() {
        this.dev.archived = !this.dev.archived
        const Device = new FormData()
        Device.append('id', this.dev.id)
        Device.append('archived', this.dev.archived)
        let response = await this.$http.put('/site/devices', Device)
        if (response.data.error) {
          Array.isArray(response.data.error) ? response.data.error.forEach(m => this.$toast.error(m)) : this.$toast.error(response.data.error)
        } else {
          this.$store.dispatch('refreshDevices', this.site.id)
          this.$store.commit('setDevice', response.data.device.id)
          if (this.dev.archived) {
            this.$toast.success('Device Archived')
          } else {
            this.$toast.success('Device Removed from Archive')
          }
        }
      },
  }
}
</script>

<style src="./Cards.scss" lang="scss"></style>