<template>
  <v-app>
    <Header />
    <SiteSidebar v-if="!isMobile" />
    <v-main :class=" isMobile ? 'mobile-content' : 'content'">
      <v-fade-transition mode="out-in">
        <router-view />
      </v-fade-transition>
    </v-main>
    <device-card v-model="deviceDialog"/>
    <create-device v-model="createDeviceDialog"/>
  </v-app>
</template>

<script>
  import {mapGetters} from 'vuex'
  import Header from '@/components/Header/Header'
  import SiteSidebar from '@/components/Sidebar/SiteSidebar'
  import DeviceCard from '@/components/Cards/DeviceCard.vue'
  import CreateDevice from '@/components/Forms/Device/CreateDevice.vue'
  import './Layout.scss';

  export default {
    name: 'SiteLayout',
    components: { Header, SiteSidebar, CreateDevice, DeviceCard },
    data(){
      return {
        permissions: null
      }
    },
    async mounted() {
      this.getPermissions() 
    },
    unmounted () {
      this.$store.commit('unsetSite')
    },
    computed: {
      ...mapGetters(['site', 'deviceDialog', 'createDeviceDialog', 'sitePermission', 'isMobile']),
    },
    methods: {
      async getPermissions () {
        const Site = new FormData()
        Site.append("id", this.$route.params.site_id)
        let response = await this.$http.post('/site/permission', Site)
        if (response.data.permission == 0) {
          this.exitSite()
        } else {
          this.$store.commit('setPermission', response.data.permission)
          this.$store.commit('setAccess', response.data.access.id)
          this.$store.commit('setDeviceHeaders', response.data.access.device_headers)
          this.$store.commit('setDeviceFilters', response.data.access.device_filters)
          
          await this.$store.dispatch('setSite', this.$route.params.site_id)
          this.$store.commit('setHeaderTitle', this.site.name)
        }
      },
    }
  };
</script>

<style src="./Layout.scss" lang="scss" />