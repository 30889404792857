// Define the initial state in a function to allow resetting
const getDefaultState = () => ({
  floorplanDialog: false,
  deviceDialog: false,
  createDeviceDialog: false,
  deviceFilterVisible: false,
  devicesLayout: 'table', // or 'grid'
})

const state = getDefaultState()

const getters = {
  floorplanDialog: state => state.floorplanDialog,
  deviceDialog: state => state.deviceDialog,
  createDeviceDialog: state => state.createDeviceDialog,
  deviceFilterVisible: state => state.deviceFilterVisible,
  devicesLayout: state => state.devicesLayout,
}

const mutations = {
  resetDialogsState(state) {
    Object.assign(state, getDefaultState())
  },
  openFloorplanDialog (state) {
    state.floorplanDialog = true
  },
  closeFloorplanDialog (state) {
    state.floorplanDialog = false
  },
  openDeviceDialog (state) {
    state.deviceDialog = true
  },
  openCreateDeviceDialog (state) {
    state.createDeviceDialog = true
  },
  closeDeviceDialog (state) {
    state.deviceDialog = false
    state.createDeviceDialog = false
  },
  toggleDeviceFilter (state) {
    state.deviceFilterVisible = !state.deviceFilterVisible
  },
  setDevicesLayout (state, index) {
    if (index === 0) {
      state.devicesLayout = 'table'  
    } else {
      state.devicesLayout = 'grid'
    }
  },
}

export default {
  state,
  getters,
  mutations
}
