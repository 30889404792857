<template>
  <v-app>
    <Header />
    <AdminSidebar />
    <v-main class="content">
      <v-fade-transition mode="out-in">
        <router-view />
      </v-fade-transition>
    </v-main>
    <v-dialog v-model="adminUserCardDialog" min-width="70%" transition="dialog-bottom-transition">
      <user-card/>
    </v-dialog>
  </v-app>
</template>

<script>
  import {mapGetters} from 'vuex'
  import Header from '@/components/Header/Header';
  import AdminSidebar from '@/components/Sidebar/AdminSidebar';
  import UserCard from '@/components/Admin/UserCard.vue'
  import './Layout.scss';

  export default {
    name: 'AdminLayout',
    components: { Header, AdminSidebar, UserCard },
    computed: {
      ...mapGetters(['adminUserCardDialog']),
    },
  }
</script>

<style src="./Layout.scss" lang="scss" />