<template>
  <v-card flat min-height="85vh">
    <v-toolbar flat dense>
      <toolbar-icon-button tooltip="Save" icon="mdi-content-save" color="primary" @click="save" tooltipLocation="top" />
    </v-toolbar>
    <v-card-text>
      <v-row>
        <v-col cols="6">
          <v-row>
            <v-col cols="12">
              <v-text-field v-model="site.name" label="Name*" required></v-text-field>
              <company-select-and-create v-model="site.customer_id" label="Customer*" :tenant_id="site.tenant_id" itemType="Customer"/>
            </v-col>
            <v-col cols="9" class="py-0">
              <v-text-field label="Street" v-model="site.street"></v-text-field>
            </v-col>
            <v-col cols="3" class="py-0">
              <v-text-field label="Street Number" v-model="site.street_number" ></v-text-field>
            </v-col>
            <v-col cols="3" class="py-0">
              <v-text-field label="Postal Code" v-model="site.zip" ></v-text-field>
            </v-col>
            <v-col cols="9" class="py-0">
              <v-text-field label="City" v-model="site.city" ></v-text-field>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-autocomplete v-model="site.country" :items="countries" label="Country" ></v-autocomplete>
            </v-col>
            <v-col cols="12" class="py-0">
              <s3-file-uploader :obj="site" fieldName="image" directory="sites" label="Upload Picture" @located="setFileLocation"/>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="6">
          <v-img
            v-if="site.image"
            height="400"
            :src="site.image"
            contain
          ></v-img>
          <v-img
            v-else
            height="400"
            src="https://wtec-uploads.s3.eu-central-1.amazonaws.com/assets/placeholder-image.png"
            contain
          ></v-img>
        </v-col>
      </v-row>
      
    </v-card-text>
  </v-card>
</template>

<script>
import {mapGetters} from 'vuex'
import S3FileUploader from '@/components/S3Upload/S3FileUploader'
import CompanySelectAndCreate from '@/components/Selectors/companies/CompanySelectAndCreate.vue'

export default {
  name: "EditSite",
  components: {
    S3FileUploader,
    CompanySelectAndCreate,
  },
  data() {
    return {
      customers: [],
      countries: ['Belgium', 'France', 'Luxemburg', 'Netherlands'],
    };
  },
  computed: {
    ...mapGetters(['site']),
  },
  mounted () {
    this.initialize()
  },
  methods: {
    async initialize () {
      let response = await this.$http.get('/site/index')
      this.customers = response.data.customers
      this.loading = false
    },
    setFileLocation (value) {
      this.site.image = value
    },
    async save () {
      const Site = new FormData()
      Site.append("name", this.site.name)
      Site.append("customer_id", this.site.customer_id)
      Site.append("image", this.site.image)
      Site.append("country", this.site.country)
      Site.append("zip", this.site.zip)
      Site.append("city", this.site.city)
      Site.append("street", this.site.street)
      Site.append("street_number", this.site.street_number)
      Site.append("id", this.site.id)
      let response = await this.$http.put('/site/sites', Site)
      if (response.data.error) {
        Array.isArray(response.data.error) ? response.data.error.forEach(m => this.$toast.error(m)) : this.$toast.error(response.data.error)
      } else {
        if (response.data.site.id === this.site.id) {
          await this.$store.dispatch('refreshSite', response.data.site.id)
          this.$toast.success('Site saved')
        } else {
          this.$toast.error('Error saving site')
        }
        
      }
    },
  },
}
</script>