<template>
  <v-container fluid class="companies-page mt-5">
    <v-toolbar flat height="90">          
      <v-select
        v-model="table"
        clearable
        flat
        color="primary"
        hide-details
        label="Select model"
        :items="tables"
      ></v-select>
      <v-btn
        color="primary"
        dark
        class="mb-2 ml-6"
        @click="fetchTable"
      >
        Get Json
      </v-btn>
      <v-spacer></v-spacer>
      <v-toolbar-title>Results: {{ items.length }}</v-toolbar-title>
    </v-toolbar>
    <v-row>
      <v-col cols="12">
        <v-card class="mt-5">
          <v-card-text>
            <pre class="wrapped">{{ JSON.stringify(items, null, 2) }}</pre>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'Json',
    data: () => ({
      items: [],
      tables: [
        "activity_logs",
        "attachments",
        "companies",
        "contacts",
        "devices",
        "floors",
        "interfaces",
        "layers",
        "locations",
        "messages",
        "procats",
        "products",
        "secrets",
        "site_accesses",
        "sites",
        "tasks",
        "tenants",
        "tickets",
        "users",
        "zones",
      ], 
      table: null,
    }),
    created () {
      this.$store.commit('setHeaderTitle', 'Admin > Exports')
    },
    methods: {
      async fetchTable () {
        if (this.table === null) {
          this.$toast.error("Select a Model first")
        } else {
          const Table = new FormData()
          Table.append("table", this.table)
          let response = await this.$http.post('/admin/jobs/export_json', Table)
          this.items = response.data.items
          this.$toast.success("done")
        }
        
      },

    }
  }
</script>

<style scoped>
  .wrapped {
    white-space: pre-wrap; /* Ensures the JSON is wrapped properly */
    word-wrap: break-word; /* Ensures long words are wrapped */
    overflow-wrap: break-word; /* Ensures long words are wrapped */
  }
</style>