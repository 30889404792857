<template>
  <v-container fluid class="config-page">
    <v-row class="mt-5">
      <v-col cols="12">
        <v-tabs vertical>
          <v-tab>
            <v-icon left>mdi-office-building</v-icon>
            Site config
          </v-tab>
          <v-tab>
            <v-icon left>mdi-lock</v-icon>
            Zones & layers
          </v-tab>
          <v-tab>
            <v-icon left>mdi-access-point</v-icon>
            Device Statuses
          </v-tab>
          <v-tab>
            <v-icon left>mdi-account-hard-hat</v-icon>
            Contractors
          </v-tab>

          <v-tab-item class="ml-8">
            <edit-site></edit-site>
          </v-tab-item>
          <v-tab-item class="ml-8">
            <zones-layers></zones-layers>
          </v-tab-item>
          <v-tab-item class="ml-8">
            <device-status></device-status>
          </v-tab-item>
          <v-tab-item class="ml-8">
            <contractors></contractors>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import {mapGetters} from 'vuex'
  import ZonesLayers from '@/components/Site/ZonesLayers.vue'
  import DeviceStatus from '@/components/Site/DeviceStatus.vue'
  import EditSite from '@/components/Forms/Site/EditSite.vue'
  import Contractors from '@/components/Site/Contractors.vue'

  export default {
    name: "Config",
    components: {
      ZonesLayers,
      DeviceStatus,
      EditSite,
      Contractors
    },
    mounted() {
      this.$store.commit('setHeaderTitle', this.site.name + ' > Config')
    },
    computed: {
      ...mapGetters(['site']),
    },
  }
</script>