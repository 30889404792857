<template>
  <v-navigation-drawer
    app
    clipped
    :permanent="$vuetify.breakpoint.mdAndUp"
    :temporary="$vuetify.breakpoint.smAndDown"
    expand-on-hover
  >
    
    <v-list>
      <v-list-item
        v-for="(item, i) in filteredItems"
        color="primary"
        :key="i"
        :to="'/site/'+$route.params.site_id+ '/'+item.link"
        link
      >
        <v-list-item-action>
          <v-icon
            size="28"
            :color="item.color ? item.color : ''"
          >{{ item.icon }}</v-icon>
        </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title
              class="grey--text"
              link>
              {{ item.title }}
            </v-list-item-title>
          </v-list-item-content>
      </v-list-item>
    </v-list>

    <template v-slot:append>
      <v-list dense>
        <v-list-item
          color="primary"
          link
          @click="exitSite">
          <v-list-item-action>
            <v-icon size="28">mdi-exit-to-app</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title
              class="grey--text"
              link>
                Exit Site
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>
 
  </v-navigation-drawer>
</template>

<script>
  import {mapGetters} from 'vuex'
  export default {
    data(){
      return {
        items: [
          { title: 'Dashboard', icon: 'mdi-monitor-dashboard', link: 'dashboard', permission: 1 },
          { title: 'Devices', icon: 'mdi-devices', link: 'devices', permission: 1 },
          { title: 'Locations', icon: 'mdi-floor-plan', link: 'locations', permission: 1 },     
          //{ title: 'Connections', icon: 'mdi-swap-horizontal', link: 'connections', permission: 1 },
          { title: 'Attachments', icon: 'mdi-paperclip', link: 'attachments', permission: 1 },
          { title: 'Vault', icon: 'mdi-shield-lock', link: 'vault', permission: 3 },
          //{ title: 'Contacts', icon: 'mdi-card-account-details', link: 'contacts', permission: 1 },
          { title: 'Access', icon: 'mdi-security', link: 'access', permission: 3 },
          { title: 'Logs', icon: 'mdi-clipboard-text-clock', link: 'logs', permission: 2 },
          { title: 'Config', icon: 'mdi-cog', link: 'config', permission: 3 },
          //{ title: 'Support', icon: 'mdi-lifebuoy', link: 'support', permission: 1 },
        ],
        mini: true,
        permissions: null
      }
    },
    unmounted () {
      this.$store.commit('unsetSite')
    },
    computed: {
      ...mapGetters(['site', 'deviceDialog', 'sitePermission']),
      filteredItems() {
        return this.items.filter(item => item.permission <= this.sitePermission)
      },

    },
    async mounted() {
      this.getPermissions() 
    },
    methods: {
      async getPermissions () {
        const Site = new FormData()
        Site.append("id", this.$route.params.site_id)
        let response = await this.$http.post('/site/permission', Site)
        if (response.data.permission == 0) {
          this.exitSite()
        } else {
          
          this.$store.commit('setPermission', response.data.permission)
          this.$store.commit('setAccess', response.data.access.id)
          this.$store.commit('setDeviceHeaders', response.data.access.device_headers)
          this.$store.commit('setDeviceFilters', response.data.access.device_filters)
          
          await this.$store.dispatch('setSite', this.$route.params.site_id)
          this.$store.commit('setHeaderTitle', this.site.name)
        }
      },
      exitSite () {
        this.$store.commit('unsetSite')
        this.$router.push('/sites')
      }
    }
  }
</script>

<style src="./Sidebar.scss" lang="scss"/>
