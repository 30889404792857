<template>
  <secrets view="site"/>
</template>

<script>
  import { mapGetters } from 'vuex'
  import Secrets from '@/components/Multimodal/Secrets'

  export default {
    name: 'Vault',
    components: {
      Secrets
    },
    data: () => ({
      loading: true,
    }),

    computed: {
      ...mapGetters(['site'])
    },

    created () {
      this.initialize()
      this.$store.commit('setHeaderTitle', this.site.name + ' > Schemas')
    },

    methods: {
      async initialize () {
        this.loading = false
      },
    },
  }
</script>