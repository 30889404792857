<template>
  <v-container fluid class="secrets-page mt-5">
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="secrets"
          :search="search"
          :loading="loading"
          key="id"
          sort-by="id"
          class="elevation-1 clickable-row"
          @click:row="editItem"
          :items-per-page="view === 'site' ? 12 : 5"
        >
          <template v-slot:top>
            <v-toolbar
              flat
              height="90"
            >
              <v-icon large class="mx-4" v-if="view === 'site'">mdi-shield-lock</v-icon>              
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                clearable
                flat
                color="primary"
                hide-details
                prepend-inner-icon="mdi-magnify"
                label="Search"
              ></v-text-field>
              <v-spacer></v-spacer>
              <v-dialog
                v-model="dialog"
                :width="isMobile ? '90%' : '40%'"
                v-if="sitePermission>1"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    New Secret
                  </v-btn>
                </template>
                <v-card>
                  <v-toolbar class="elevation-2" color="grey lighten-3 mb-5">
                    <v-toolbar-title class="headline primary--text">{{ formTitle }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-tooltip top v-if="editedIndex > -1">
                      <template v-slot:activator="{ on }">
                        <v-btn icon v-on="on" @click="deleteDialog = true" color="error">
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </template>
                      <span>Delete</span>
                    </v-tooltip>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn icon v-on="on" @click="save" color="primary">
                          <v-icon>mdi-content-save</v-icon>
                        </v-btn>
                      </template>
                      <span>Save</span>
                    </v-tooltip>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn icon v-on="on" @click="close" color="error">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </template>
                      <span>Close</span>
                    </v-tooltip>
                  </v-toolbar>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col v-if="mySecret" cols="3">
                          <v-switch
                            v-if="mySecret"
                            v-model="editedItem.private"
                            inset
                            class="ml-5"
                            :label="editedItem.private ? 'Private' : 'Shared'"
                          ></v-switch>
                        </v-col>
                        <v-col cols="12" :md="mySecret ? 9 : 12">
                          <v-text-field
                            v-model="editedItem.name"
                            label="Name"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="editedItem.username"
                            label="Username"
                            prepend-icon="mdi-shield-account"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="editedItem.password"
                            label="password"
                            prepend-icon="mdi-lock"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.url"
                            label="Url"
                            prepend-icon="mdi-web"
                          ></v-text-field>
                        </v-col>
                        <!-- <v-col cols="6">
                          <v-select
                            v-model="editedItem.level"
                            :items="[1,2,3]"
                            label="Level"
                            prepend-icon="mdi-poll"
                            clearable
                          ></v-select>
                        </v-col> -->
                        <v-col cols="12">
                          <multimodal-selector :selector_key.sync="selectorKey" :polymorphic_id.sync="editedItem.secretable_id" :polymorphic_type.sync="editedItem.secretable_type" @linked="editSecretable"/>
                        </v-col>
                        <v-col cols="12">
                          <v-textarea
                            v-model="editedItem.comment"
                            outlined
                            label="Comment"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-dialog>
              <v-dialog v-model="deleteDialog" width="600px" v-if="sitePermission>1">
                <v-card class="text-center py-3">
                  <v-icon style="font-size: 64px" color="error" class="my-5">mdi-delete</v-icon>
                  <v-card-title>
                    <v-spacer />
                    <div class="text-center">
                      <h3 class="headline error--text mb-0">Delete {{editedItem.name}}</h3>
                      <h3 class="headline error--text mb-0">Are you Sure ?</h3>
                      <div>Deleted items cannot be retrieved</div>
                    </div>
                    <v-spacer />
                  </v-card-title>
                  <v-card-actions class="mt-5">
                    <v-spacer />
                    <v-btn @click="deleteDialog = false">Cancel</v-btn>
                    <v-btn @click="deleteSecret" color="error">Confirm</v-btn>
                    <v-spacer />
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:[`item.password`]="{ item }">
            <span v-if="visibleKey === item.id">{{item.password}}</span>
            <span v-else>{{"*".repeat(item.password.length)}}</span>
            <v-icon
              small
              class="float-right"
              @click.stop.prevent="togglePwdVis(item.id)"
              color="info"
              v-if="sitePermission>1"
            >
              <template v-if="visibleKey === item.id">
                mdi-eye-off
              </template>
              <template v-else>
                mdi-eye
              </template>
            </v-icon>
          </template>
          <template v-slot:[`item.linked`]="{ item }">
            {{ showLinked(item.secretable_type, item.secretable_id) }}
          </template>
          <template v-slot:no-data>
            No secrets for this {{view}}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex'
  import MultimodalSelector from '@/components/Selectors/MultimodalSelector'

  export default {
    name: 'Secrets',
    components: {
      MultimodalSelector
    },
    data: () => ({
      search: '',
      loading: true,
      dialog: false,
      deleteDialog: false,
      visibleKey: -1,
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Name', value: 'name' },
        { text: 'username', value: 'username' },
        { text: 'password', value: 'password' },
        { text: 'url', value: 'url' },
        { text: 'Linked To', value: 'linked' },
      ],
      editedIndex: -1,
      editedItem: {
        name: '',
        site_id: '',
        device_id: '',
        url: '',
        username: '',
        password: '',
        comment: '',
        level: 0,
        secretable_id: null,
        secretable_type: null,
        private: false,
        user_id: null
      },
      defaultItem: {
        name: '',
        site_id: '',
        device_id: '',
        url: '',
        username: '',
        password: '',
        comment: '',
        level: 0,
        secretable_id: null,
        secretable_type: null,
        private: false,
        user_id: null
      },
      selectorKey: 0,
    }),
    props: ['view'],
    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'New Secret' : 'Edit Secret'
      },
      ...mapGetters(['site', 'floor', 'location', 'device', 'sitePermission', 'siteDevices', 'StateUser', 'isMobile']),
      secrets () {
        let availableSecrets = this.site.secrets.filter(secret => (secret.private === true && secret.user_id === this.StateUser.id) || secret.private === false)
        if (this.view === 'site') {
          return availableSecrets
        } else if (this.view === 'location') {
          if (this.floor.id) {
            if (this.location.id) {
              return availableSecrets.filter( o => o.secretable_id == this.location.id && o.secretable_type == 'Location')
            } else {
              return availableSecrets.filter( o => o.secretable_id == this.floor.id && o.secretable_type == 'Floor')
            }
          } else {
            return []
          }
        } else if (this.view === 'device') {
          return availableSecrets.filter(o => o.secretable_id === this.device.id && o.secretable_type == 'Device')
        } else {
          return []
        }
      },
      mySecret () {
        return this.StateUser.id === this.editedItem.user_id || this.editedIndex === -1
      }

    },

    watch: { 
      dialog: function() {
        if (this.dialog == false) {
          this.close()
        }
      }
    },

    created () {
      this.initialize()
    },

    methods: {
      async initialize () {
        this.loading = false

        if (this.view === 'location') {
          if (this.floor.id) {
            if (this.location.id) {
              this.editedItem.secretable_type = 'Location'
            } else {
              this.editedItem.secretable_type = 'Floor'
            }
          }
        } else if (this.view === 'device') {
          this.editedItem.secretable_type =  "Device"
        } 

        if (this.view === 'location') {
          if (this.floor.id) {
            if (this.location.id) {
              this.editedItem.secretable_id = this.location.id
            } else {
              this.editedItem.secretable_id = this.floor.id
            }
          } 
        } else if (this.view === 'device') {
          this.editedItem.secretable_id = this.device.id
        } 
      },

      editItem (item) {
        if (this.sitePermission > 1) {
          this.editedIndex = this.site.secrets.indexOf(item)
          this.editedItem = Object.assign({}, item)
          this.selectorKey = this.selectorKey + 1
          this.dialog = true
        }
      },

      async deleteSecret () {
        let response = await this.$http.delete('/site/secrets', {params: {id: this.editedItem.id}})
        if (response.data.error) {
          Array.isArray(response.data.error) ? response.data.error.forEach(m => this.$toast.error(m)) : this.$toast.error(response.data.error)
        } else {
          this.$store.dispatch('refreshSite', this.site.id)
          this.$toast.success("Secret Deleted")
        }
        this.close()
      },

      close () {
        this.selectorKey = this.selectorKey + 1
        this.dialog = false
        this.deleteDialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      editSecretable(type, id) {
        this.editedItem.secretable_type = type
        this.editedItem.secretable_id = id
      },

      async save () {
        if (this.editedItem.device_id == null) { this.editedItem.device_id = ""} 
        const Secret = new FormData()
        Secret.append("device_id", this.editedItem.device_id)
        Secret.append("name", this.editedItem.name)
        Secret.append("url", this.editedItem.url)
        Secret.append("username", this.editedItem.username)
        Secret.append("password", this.editedItem.password)
        Secret.append("comment", this.editedItem.comment)
        Secret.append("level", this.editedItem.level)
        Secret.append("secretable_type", this.editedItem.secretable_type)
        Secret.append("secretable_id", this.editedItem.secretable_id)
        Secret.append("private", this.editedItem.private)
        
        if (this.editedIndex > -1) {
          Secret.append("id", this.editedItem.id);
          let response = await this.$http.put('/site/secrets', Secret)
          //needs error handling
          this.$store.dispatch('refreshSite', this.site.id)
          this.$toast.success("Secret Updated");
        } else {
          Secret.append("site_id", this.site.id)
          let response = await this.$http.post('/site/secrets', Secret)
          //needs error handling
          this.$store.dispatch('refreshSite', this.site.id)
          this.$toast.success("Secret Created");
        }
        this.close()
      },
      togglePwdVis (id) {
        if (this.visibleKey === id) {
          this.visibleKey = -1
        } else {
          this.visibleKey = id
        }
      },

      showLinked(type, id) {
        if (type == 'Location') {
          let loc = []
          this.site.floors.forEach(f => loc.push(f.locations.find(o => o.id == id )))
          loc = loc.filter(o => o)
          return 'Location || ' + loc[0].name + ' || ' + loc[0].code
        } else if (type == 'Floor') {
          let f = this.site.floors.find( o => o.id == id )
          return 'Floor || ' + f.name + ' || ' + f.code
        } else if (type == 'Device') {
          let dev = this.siteDevices.find( o => o.id == id )
          return 'Device || ' + dev.name + ' || ' + dev.code
        } else {
          return ''
        }
      }
    },
  }
</script>