<template>
  <v-container fluid class="admin-page">
    <v-row class="mt-10 mb-6">
      <v-col cols="12">
        <h1 class="page-title">Administration</h1>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import DeviceCard from '@/components/Cards/DeviceCard.vue'

  export default {
    name: 'Admin',
    components: {
      DeviceCard
    },
    data () {
      return {
        showSheet: false,
        name: 'Bose Freespace 51',  
        mac: 'F4:20:15:A7:F5:98',
        prompt: null, 
      }
    },
    mounted() {
      this.$store.commit('setHeaderTitle', 'Admin')
    },

    methods: {
      async setSiteAccesses() {
        let response = await this.$http.get('/admin/jobs/set_site_accesses')
        this.$toast.success(response.data.message);
      },
      async addNameToRessources() {
        let response = await this.$http.get('/admin/jobs/add_name_to_ressources')
        this.$toast.success(response.data.message);
      },
      async convert_activity_logs() {
        let response = await this.$http.get('/admin/jobs/convert_activity_logs')
        this.$toast.success(response.data.message);
      },
      async add_floor_to_zones() {
        let response = await this.$http.get('/admin/jobs/add_floor_to_zones')
        this.$toast.success(response.data.message);
      },
      async refresh_device_headers() {
        let response = await this.$http.get('/admin/jobs/refresh_device_headers')
        this.$toast.success(response.data.message);
      },
      async assign_secrets_to_users() {
        let response = await this.$http.get('/admin/jobs/assign_secrets_to_users')
        this.$toast.success(response.data.message);
      }, 
    }
  }
</script>