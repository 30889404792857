<template>
  <v-list-item @click="download">
    <v-list-item-icon>
      <v-icon>mdi-file-export-outline</v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      Export Products
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import { utils, writeFile } from "xlsx"

export default {
  name: "XlsProductsDownloader",
  data() {
    return {
      exportData: [],
      filename: "Wikibuild - Products.xlsx"
    };
  },
  props: ['products'],
  computed: {
  },
  methods: {
    download () {
      this.products.forEach( (product) => {
        let row = {}
        row.id = product.id
        row.tenant_id = product.tenant_id
        row.procat_id = product.procat_id
        row.name = product.name
        row.product_model = product.product_model
        row.product_url = product.product_url
        row.image = product.image
        row.product_status = product.product_status
        row.description = product.description
        row.features = product.features
        row.height = product.height
        row.width = product.width
        row.depth = product.depth
        row.weight = product.weight
        row.rack_mounted = product.rack_mounted
        row.rack_units = product.rack_units
        row.preferred_supplier_id = product.preferred_supplier_id
        row.brand = product.brand
        row.power = product.power
        row.sku = product.sku
        row.upc = product.upc
        row.ean = product.ean
        row.created_at = product.created_at
        row.updated_at = product.updated_at
        row.discarded_at = product.discarded_at
        row.brand_id = product.brand_id

        this.exportData.push(row)
      })
      const comp = utils.json_to_sheet(this.exportData)
      const wb = utils.book_new()
      utils.book_append_sheet(wb, comp, 'Products')
      writeFile(wb, this.filename)
    },
  },
};
</script>




