import Vue from 'vue'
import DatePickerMenu from '@/components/Inputs/DatePickerMenu'
import InlineTextInput from '@/components/Inputs/InlineTextInput'
import InlineTextArea from '@/components/Inputs/InlineTextArea'
import InlineSelectInput from '@/components/Inputs/InlineSelectInput'
import InlineDatePicker from '@/components/Inputs/InlineDatePicker'
import ToolbarIconButton from '@/components/Inputs/ToolbarIconButton'
import TableSearchField from '@/components/Inputs/TableSearchField'

Vue.component('DatePickerMenu', DatePickerMenu)
Vue.component('InlineTextInput', InlineTextInput)
Vue.component('InlineTextArea', InlineTextArea)
Vue.component('InlineSelectInput', InlineSelectInput)
Vue.component('InlineDatePicker', InlineDatePicker)
Vue.component('ToolbarIconButton', ToolbarIconButton)
Vue.component('TableSearchField', TableSearchField)