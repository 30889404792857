import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import auth from './modules/auth'
import header from './modules/header'
import settings from './modules/settings'
import constants from './modules/constants'
import site from './modules/site'
import support from './modules/support'
import s3 from './modules/s3'
import admin from './modules/admin'
import tenant from './modules/tenant'
import plan from './modules/plan'
import ai from './modules/ai'
import dialogs from './modules/dialogs'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    header,
    settings,
    constants,
    site,
    support,
    s3,
    admin,
    tenant,
    plan,
    ai,
    dialogs,
  },
  actions: {
    resetAllStates({ commit }) {
      commit('resetAuthState', null, { root: true })
      commit('resetHeaderState', null, { root: true })
      commit('resetSettingsState', null, { root: true })
      commit('resetConstantsState', null, { root: true })
      commit('resetSiteState', null, { root: true })
      commit('resetSupportState', null, { root: true })
      commit('resetS3State', null, { root: true })
      commit('resetAdminState', null, { root: true })
      commit('resetTenantState', null, { root: true })
      commit('resetPlanState', null, { root: true })
      commit('resetAiState', null, { root: true })
      commit('resetDialogsState', null, { root: true })
    },
  },
  plugins: [
    createPersistedState()
  ]
})
