<template>
  <div>
    <v-list-item @click="selectFile">
      <v-list-item-icon>
        <v-icon>mdi-file-import-outline</v-icon>
      </v-list-item-icon>

      <v-list-item-content>
        Import Devices from excel
      </v-list-item-content>
      <v-file-input
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        @change="excelImport"
        ref="fileInput"
        name="excel-input"
        id="input"
        class="d-none"
      ></v-file-input>
    </v-list-item>
    <v-dialog v-model="dialog">
      <v-card>
        <v-toolbar class="elevation-2" color="grey lighten-3 mb-5">
          <v-toolbar-title class="headline primary--text">Import Devices Wizard</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on" @click="save" color="primary">
                <v-icon>mdi-upload</v-icon>
              </v-btn>
            </template>
            <span>Upload</span>
          </v-tooltip>
          <v-tooltip top v-if="stepper > 1">
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on" @click="stepper--">
                <v-icon>mdi-arrow-left</v-icon>
              </v-btn>
            </template>
            <span>Previous</span>
          </v-tooltip>
          <v-tooltip top v-if="stepper < 3">
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on" @click="stepper++">
                <v-icon>mdi-arrow-right</v-icon>
              </v-btn>
            </template>
            <span>Next</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on" @click="dialog = false"  color="error">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
            <span>Close</span>
          </v-tooltip>
        </v-toolbar>
        <v-stepper v-model="stepper" non-linear>
          <v-stepper-header>
            <v-stepper-step :complete="stepper > 1" step="1" editable >
              Columns
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="stepper > 2" step="2" editable >
              Rows
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="stepper > 3" step="3" editable >
              Validate and Upload
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>

            <v-stepper-content step="1">
              <v-card height="600px">
                <v-row>
                  <v-col cols="2">
                    <v-subheader class="primary--text">Excel Device Columns</v-subheader>
                    <v-card>
                      <v-list dense nav>
                        <v-list-item-group color="primary">
                          <v-list-item
                            v-for="selection in deviceHeaders"
                            :key="selection.value"
                          >
                            <v-list-item-content>
                              <v-list-item-title >{{ selection.value }}</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-icon>
                              <v-icon>mdi-close</v-icon>
                            </v-list-item-icon>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list> 
                    </v-card>
                  </v-col>
                  <v-col cols="2">
                    <v-subheader class="primary--text">Excel Device Columns</v-subheader>
                    <v-card>
                      <v-list dense nav>
                        <v-list-item-group color="primary">
                          <v-list-item
                            v-for="selection in deviceHeaders"
                            :key="selection.value"
                          >
                            <v-list-item-content>
                              <v-list-item-title >{{ selection.value }}</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-icon>
                              <v-icon>mdi-close</v-icon>
                            </v-list-item-icon>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list> 
                    </v-card>
                  </v-col>
                </v-row>
              </v-card>
            </v-stepper-content>
            <v-stepper-content step="2">
              <v-card height="525px">
                <v-row>
                  <v-col cols="6">
                    hello Rows
                  </v-col>
                  <v-col cols="6">
                    Hello other rows
                  </v-col>
                </v-row>
              </v-card>
            </v-stepper-content>
            <v-stepper-content step="3">
              <v-card height="525px">
                <v-row>
                  <v-col cols="4">
                    hello validation
                  </v-col>
                  <v-col cols="4">
                    hello upload
                  </v-col>
                </v-row>
              </v-card>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card>
      <v-card class="mt-16">
        <v-data-table
          :headers="excelHeaders"
          :items="excelData"
          :items-per-page="5"
          :loading="loading"
          loading-text="Processing... Please wait"
          class="elevation-1"
        ></v-data-table>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import readXlsxFile from 'read-excel-file'

export default {
  name: "XlsDeviceUploader",
  data() {
    return {
      excelData: [],
      excelHeaders: [],
      errorDevices: [],
      loading: false,
      dialog: false,
      stepper: 1,
    };
  },
  computed: {
    ...mapGetters(['site']),
    deviceHeaders () {
      return this.excelHeaders.filter(o => !o.value.includes(".") && !o.value.includes("_"))
    },
    relationHeaders () {
      return this.excelHeaders.filter(o => o.value.includes("."))
    },
  },
  methods: {
    selectFile() {
      let fileInputElement = document.getElementById('input')
      fileInputElement.click();
    },
    excelImport () {
      this.excelData = []
      this.excelHeaders = []
      this.errorDevices = []
      readXlsxFile(document.getElementById('input').files[0]).then((rows) => {
        let devices = []
        let headers = rows.shift()
        rows.forEach((row) => {
          const obj = {}
          row.forEach((prop, index) => {
            obj[headers[index]] = prop
          })
          devices.push(obj)
        })
        this.excelData = devices
        headers.forEach((header) => {
          let h = {}
          h["value"] = header
          h["text"] = header
          this.excelHeaders.push(h)
        })
      })
      this.dialog = true
    },
    async save() {
      this.loading = true
      this.excelData.forEach( async (device) => {
        const Device = new FormData()
        if (device.floor_id != null) { Device.append('floor_id', device.floor_id)}
        if (device.location_id != null) { Device.append('location_id', device.location_id)}
        if (device.zone_id != null) { Device.append('zone_id', device.zone_id)}
        if (device.layer_id != null) { Device.append('layer_id', device.layer_id)}
        if (device.supplier_id != null) { Device.append('supplier_id', device.supplier_id)}
        if (device.contractor_id != null) { Device.append('contractor_id', device.contractor_id)}
        if (device.name != null) { Device.append('name', device.name)}
        if (device.code != null) { Device.append('code', device.code)}
        if (device.sn != null) { Device.append('sn', device.sn)}
        if (device.mac != null) { Device.append('mac', device.mac)}
        if (device.ip != null) { Device.append('ip', device.ip)}
        if (device.patch != null) { Device.append('patch', device.patch)}
        if (device.port != null) { Device.append('port', device.port)}
        if (device.note != null) { Device.append('note', device.note)}
        if (device.status != null) { Device.append('status', device.status)}
        if (device.purchase_date != null) { Device.append('purchase_date', device.purchase_date)}
        if (device.warranty_expiration_date != null) { Device.append('warranty_expiration_date', device.warranty_expiration_date)}
        
        if (device.id != null) { 
          Device.append('id', device.id)
          let response = await this.$http.put('/site/devices', Device)
          if (response.data.error) {
            this.errorDevices.push(device)
          }
        } else {
          if (device.product_id != null) { 
            Device.append('product_id', device.product_id)
            Device.append('site_id', this.site.id)
            let response = await this.$http.post('/site/devices', Device)
            if (response.data.error) {
              this.errorDevices.push(device)
            }
          } else {
            this.errorDevices.push(device)
          }
        }
      })  
      this.excelData = []
      this.excelHeaders = []
      this.errorDevices = []
      if (this.errorDevices.length === 0) {
        this.$toast.success("Devices uploaded") 
      } else {
        this.$toast.error("Upload incomplete check errors")
      }
      await this.$store.dispatch('refreshDevices', this.site.id)
      await this.$store.dispatch('refreshProducts', this.site.id)
      await this.$store.dispatch('refreshProcats', this.site.id)
      this.loading = false
      this.dialog = false
    },
  },
};
</script>