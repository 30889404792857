<template>
  <v-dialog
    v-model="dialogVisible"
    :fullscreen="isMobile"
    transition="custom-slide-x-reverse-transition"
    @keydown.esc="closeDialog" 
    @click:outside="closeDialog"
  >
    <v-card>
      <v-toolbar class="elevation-2" color="grey lighten-3 mb-5">
        <v-toolbar-title class="headline primary--text">Choose Product</v-toolbar-title>
        <v-spacer></v-spacer>
        <toolbar-icon-button tooltip="Close" icon="mdi-close" color="error" @click="closeDialog" />
      </v-toolbar>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="10">
            <v-text-field v-model="search" prepend-inner-icon="mdi-magnify" label="Search" flat dense clearable></v-text-field>
          </v-col>
          <v-col cols="2" :class="isMobile ? 'offset-4' : ''">
            <create-product @done="createProduct" :procats="procats" block/>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card v-if="!isMobile" height="525px">
        <v-card-text>
          <v-data-table
            dense
            :headers="headers"
            :items="filteredProducts"
            :loading="loading"
            item-key="id"
            sort-by="id"
            sort-desc
            height="445px"
            fixed-header
            disable-pagination
            hide-default-footer
          >
            <template v-slot:item="{ item }">
              <tr @click="selectProduct(item)" :class="{'primary white--text': item.id === selectedProduct}">
                <td><v-img max-height="50" max-width="60" contain :src="item.image"></v-img></td>
                <td>{{item.brand?.name}}</td>
                <td>{{item.product_model}}</td>
                <td>{{item.name}}</td>
                <td>{{item.procat?.name}}</td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>

      <v-card v-else>
        <v-data-iterator
          dense
          :items="filteredProducts"
          :loading="loading"
          item-key="id"
          sort-by="id"
          :sort-desc="true"
          disable-pagination
          hide-default-footer
        >
          <template v-slot:default="{ items }">
            <v-row class="mx-0">
              <v-col v-for="item in items" :key="item.id" cols="6">
                <v-card
                  class="mx-2 cpointer mt-5"
                  @click="selectProduct(item)"
                  elevation="2"
                >
                  <v-img v-if="item.image" height="100" :src="item.image"  >
                    <div class="bottom-title">{{item.product_model}} <br/> {{item.procat.name}}</div>
                  </v-img>
                  <v-img v-else height="100" src="https://wtec-uploads.s3.eu-central-1.amazonaws.com/assets/placeholder-image.png" contain>
                    <div class="bottom-title">{{item.product_model}} <br/> {{item.procat.name}}</div>
                  </v-img>
                </v-card>
              </v-col>
            </v-row>
          </template>
        </v-data-iterator>
      </v-card>
    
    </v-card>
  </v-dialog>
</template>

<script>
  import { mapGetters } from 'vuex'
  import ImageUploader from '@/components/S3Upload/ImageUploader'
  import CreateProduct from '@/components/Forms/Product/CreateProduct'

  export default {
    name: 'NewDevice',
    components: {
      ImageUploader,
      CreateProduct,
    },
    props: {
      value: { type: Boolean, required: true },
    },
    data: () => ({
      search:"",
      headers: [
        { text: 'image', value: 'image', sortable: false },
        { text: 'Brand' },
        { text: 'Model' },
        { text: 'Description' },
        { text: 'Category' }
      ],
      products: [],
      brands: [],
      selectedProduct: -1,
      loading: true,
    }),
    computed: {
      dialogVisible: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        }
      },
      filteredProducts() {
        if (!this.search) {
          return this.products
        }
        const searchLower = this.search.toLowerCase();
        return this.products.filter(product => {
          return Object.values(product).some(value =>
            String(value).toLowerCase().includes(searchLower)
          )
        })
      },
      ...mapGetters(['site', 'floor', 'location', 'StateUser', 'procats', 'isMobile']),
    },
    watch: {
      dialogVisible(newVal) {
        newVal ? this.initialize() : this.closeDialog()
      },
    },
    created () {
      this.initialize()
    },
    methods: {
      async initialize () {
        let response = await this.$http.get('/site/devices/new')
        this.products = response.data.products
        this.brands = response.data.brands
        this.loading = false
      },
      selectProduct(product) {
        this.selectedProduct = product.id
        this.save()
      },
      async save() {
        const Device = new FormData()
        Device.append('product_id', this.selectedProduct)
        Device.append('site_id', this.site.id)
        //investigation needed add contractor id
        let response = await this.$http.post('/site/devices', Device )
        await this.$store.dispatch('refreshCompanies', response.data.device.site_id)
        await this.$store.dispatch('refreshProducts', response.data.device.site_id)
        await this.$store.dispatch('refreshDevices', response.data.device.site_id)
        this.closeDialog()
        this.$toast.success("Device Created")
        this.$store.commit('setDevice', response.data.device.id)
        setTimeout(() => {
          this.$store.commit('openDeviceDialog')
        }, 500)
      },
      closeDialog() {
        this.$store.commit('closeDeviceDialog')
        this.selectedProduct = -1
        this.search = ""
      },

      async createProduct(product) {
        this.products.unshift(product)
        this.selectProduct(product)
      },
    }
  }
</script>

<style scoped>
.bottom-title {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.5); /* Optional: Add a background for better readability */
  color: white; /* Optional: Change text color for better readability */
  padding: 5px; /* Optional: Add padding */
  margin: 0; /* Optional: Remove margin */
}
</style>