<template>
  <div>
    <v-list-item @click="selectFile">
      <v-list-item-icon>
        <v-icon>mdi-file-import-outline</v-icon>
      </v-list-item-icon>

      <v-list-item-content>
        Import Products
      </v-list-item-content>
      <v-file-input
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        @change="excelImport"
        ref="fileInput"
        name="excel-input"
        id="input"
        class="d-none"
      ></v-file-input>
    </v-list-item>
    <v-dialog v-model="dialog">
      <v-card min-height="600px">
        <v-toolbar class="elevation-2" color="grey lighten-3 mb-5">
          <v-toolbar-title class="headline primary--text">Import Products Wizard</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on" @click="save" color="primary">
                <v-icon>mdi-upload</v-icon>
              </v-btn>
            </template>
            <span>Upload</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on" @click="dialog = false" color="error">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
            <span>Close</span>
          </v-tooltip>
        </v-toolbar>
        <v-container>
          <v-row align="center">
            <v-col cols="12">
              <v-data-table
                :headers="excelHeaders"
                :items="excelData"
                :items-per-page="5"
                :loading="loading"
                loading-text="Processing... Please wait"
                class="elevation-1"
              ></v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import readXlsxFile from 'read-excel-file'

export default {
  name: "XlsProductsUploader",
  data() {
    return {
      excelData: [],
      excelHeaders: [],
      errorProducts: [],
      loading: false,
      dialog: false,
    };
  },
  computed: {
  },
  methods: {
    selectFile() {
      let fileInputElement = document.getElementById('input')
      fileInputElement.click();
    },
    excelImport () {
      this.excelData = []
      this.excelHeaders = []
      readXlsxFile(document.getElementById('input').files[0]).then((rows) => {
        let products = []
        let headers = rows.shift()
        rows.forEach((row) => {
          const obj = {}
          row.forEach((prop, index) => {
            obj[headers[index]] = prop
          })
          products.push(obj)
        })
        this.excelData = products
        headers.forEach((header) => {
          let h = {}
          h["value"] = header
          h["text"] = header
          this.excelHeaders.push(h)
        })
      })
      this.dialog = true
    },
    async save() {
      this.loading = true
      this.excelData.forEach( async (product) => {
        const Product = new FormData()
        if (product.tenant_id != null) {Product.append('tenant_id', product.tenant_id)}
        if (product.procat_id != null) {Product.append('procat_id', product.procat_id)}
        if (product.name != null) {Product.append('name', product.name)}
        if (product.product_model != null) {Product.append('product_model', product.product_model)}
        if (product.product_url != null) {Product.append('product_url', product.product_url)}
        if (product.product_status != null) {Product.append('product_status', product.product_status)}
        if (product.description != null) {Product.append('description', product.description)}
        if (product.features != null) {Product.append('features', product.features)}
        if (product.height != null) {Product.append('height', product.height)}
        if (product.width != null) {Product.append('width', product.width)}
        if (product.depth != null) {Product.append('depth', product.depth)}
        if (product.weight != null) {Product.append('weight', product.weight)}
        if (product.rack_mounted != null) {Product.append('rack_mounted', product.rack_mounted)}
        if (product.rack_units != null) {Product.append('rack_units', product.rack_units)}
        if (product.preferred_supplier_id != null) {Product.append('preferred_supplier_id', product.preferred_supplier_id)}
        if (product.brand != null) {Product.append('brand', product.brand)}
        if (product.power != null) {Product.append('power', product.power)}
        if (product.sku != null) {Product.append('sku', product.sku)}
        if (product.upc != null) {Product.append('upc', product.upc)}
        if (product.ean != null) {Product.append('ean', product.ean)}
        if (product.brand_id != null) {Product.append('brand_id', product.brand_id)}
        
        if (product.id != null) { 
          Product.append('id', product.id)
          let response = await this.$http.put('/admin/products', Product)
          if (response.data.error) { this.errorProducts.push(product) } 
        } else {
          let response = await this.$http.post('/admin/products', Product)
          if (response.data.error) { this.errorProducts.push(product) } 
        }
      })
      
      if (this.errorProducts.length === 0) {
        this.$toast.success("Products uploaded")
        this.dialog = false
        this.excelData = []
        this.excelHeaders = []
        this.errorProducts = []
        this.$emit('uploaded')
      } else {
        this.$toast.error("Upload incomplete check errors")
      }
      this.loading = false
    },
  },
};
</script>