<template>
  <v-container fluid>
    <v-data-iterator
      :items="searchableContacts"
      :search="search"
      disable-pagination
      hide-default-footer
    >
      <template v-slot:header>
        <v-toolbar flat height="90">    
          <v-icon large class="mx-4">mdi-card-account-details</v-icon>         
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            clearable
            flat
            color="primary"
            hide-details
            prepend-inner-icon="mdi-magnify"
            label="Search"
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            dark
            class="mb-2"
            @click="dialog = true"
            v-if="sitePermission>1"
          >
            Add Contact
          </v-btn>
        </v-toolbar>
        <v-dialog v-model="dialog" width="400px" v-if="sitePermission>1">
          <v-card>
            <v-toolbar class="elevation-2" color="grey lighten-3 mb-5">
              <v-toolbar-title class="headline primary--text">{{formTitle}}</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on" @click="save" color="primary" :disabled="saveDisabled">
                    <v-icon>mdi-content-save</v-icon>
                  </v-btn>
                </template>
                <span>Save</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on" @click="close" color="error">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </template>
                <span>Close</span>
              </v-tooltip>
            </v-toolbar>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="10">
                    <v-text-field
                      v-model="editedItem.email"
                      label="email"
                      prepend-icon="mdi-shield-account"
                      :error-messages="errorsSave"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="1">
                    <v-btn icon @click="findUser" class="mt-5">
                      <v-icon>mdi-check</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-expand-transition>
              <div v-show="registred" v-if="editedItem.user_id">
                <v-card-subtitle class="text-center py-0">
                  This email belongs to a registred user,<br/> 
                  contact info are listed below
                </v-card-subtitle>
                <v-list two-line>
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon color="primary">mdi-account</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{editedItem.user.lname}}</v-list-item-title>
                      <v-list-item-subtitle>{{editedItem.user.fname}}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon color="primary">mdi-mail</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{editedItem.user.email}}</v-list-item-title>
                      <v-list-item-subtitle>email</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon color="primary">mdi-phone</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{editedItem.user.phone1}}</v-list-item-title>
                      <v-list-item-subtitle>Phone</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon color="primary">mdi-domain</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{showCompany(editedItem.user.company_id)}}</v-list-item-title>
                      <v-list-item-subtitle>Company</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <v-col cols="12">
                  <v-text-field
                    v-model="editedItem.job_description"
                    label="Job Description"
                    prepend-icon="mdi-tools"
                  ></v-text-field>
                </v-col>
              </div>
            </v-expand-transition>
            <v-expand-transition>
              <div v-show="unregistred" v-if="editedItem.user_id == null">
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="editedItem.fname"
                          label="First Name"
                          prepend-icon="mdi-account"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          v-model="editedItem.lname"
                          label="Last Name"
                          prepend-icon="mdi-account-multiple"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          v-model="editedItem.phone1"
                          label="Phone"
                          prepend-icon="mdi-phone"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-combobox v-model="editedItem.company_id" :items="companies" item-text="name" item-value="id" label="Company" prepend-icon="mdi-domain">
                          <template v-slot:no-data>
                            <v-list-item>
                              <v-list-item-content>
                                <v-list-item-title>
                                  No results matching your search. Press <kbd>enter</kbd> to create a new company
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </template>
                        </v-combobox>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          v-model="editedItem.job_description"
                          label="Job Description"
                          prepend-icon="mdi-tools"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </div>
            </v-expand-transition>
          </v-card>
        </v-dialog>
      </template>

      <template v-slot:default="props">
        <v-row class="mt-5">
          <v-col
            v-for="item in props.items"
            :key="item.id"
            cols="12"
            sm="6"
            md="4"
            lg="2"
          >
            <v-card>
              <v-card-title class="primary--text">
                <div v-if="item.user_id">
                  {{item.user.fname + " " + item.user.lname}}
                </div>
                <div v-else>
                  {{item.fname + " " + item.lname}}
                </div>
              </v-card-title>
              <v-card-subtitle>
                <div v-if="item.user_id">
                  {{showCompany(item.user.company_id)}} - {{item.job_description}}
                </div>
                <div v-else>
                  <span v-if="item.company_name">
                    {{item.company_name}}
                  </span>
                  <span v-else>
                    {{showCompany(item.company_id)}}
                  </span>
                  - {{item.job_description}}
                </div>
              </v-card-subtitle>

              <v-divider></v-divider>

              <v-list two-line>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="primary">mdi-phone</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-if="item.user">{{item.user.phone1}}</v-list-item-title>
                    <v-list-item-title v-else>{{item.phone1}}</v-list-item-title>
                    <v-list-item-subtitle>Phone</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="primary">mdi-email</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-if="item.user_id">{{item.user.email}}</v-list-item-title>
                    <v-list-item-title v-else>{{item.email}}</v-list-item-title>
                    <v-list-item-subtitle>Email</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-divider v-if="sitePermission>1"></v-divider>
              <v-card-actions class="d-flex justify-end" v-if="sitePermission>1">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on" @click="editItem(item)" color="primary">
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit</span>
                </v-tooltip>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </template>
    </v-data-iterator>
  </v-container>
</template>

<script>
  import {mapGetters} from 'vuex'

  export default {
    name: "Contacts",
    data() {
      return {
        search: '',
        filter: {},
        loading: true,
        dialog: false,
        saveDisabled: true,
        errorsSave: "",
        editedIndex: -1,
        editedItem: {
          user_id: null,
          company_name: "",
          fname: "",
          lname: "",
          email: "",
          phone1: "",
          phone2: "",
          company_id: "",
          job_description: "",
          user: {
            lname: "",
            company_id: 0,
            email: ""
          }
        },
        defaultItem: {
          user_id: null,
          company_name: "",
          fname: "",
          lname: "",
          email: "",
          phone1: "",
          phone2: "",
          company_id: "",
          job_description: "",
          user: {
            lname: "",
            company_id: 0,
            email: ""
          }
        },
        unregistred: true,
        registred: false,
        companies: []
      }
    },
    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'New Contact' : 'Edit Contact'
      },
      searchableContacts () {
        let contacts = []
        this.site.contacts.forEach((contact) => {
          if (contact.user_id > 0) {
            contact.fname = contact.user.fname
            contact.lname = contact.user.lname
            contact.email = contact.user.email
            contact.phone1 = contact.user.phone1
            contact.phone2 = contact.user.phone2
            //needs more code to make company searchable only id for the moment
          }
          contacts.push(contact)
        })
        return contacts
      },
      ...mapGetters(['site', 'sitePermission']),
    },
    mounted() {
      this.initialize
    },
    methods: {
      async initialize () {
        let response = await this.$http.get('/global/company_list')
        this.companies = response.data.companies
        this.loading = false
      },
      showCompany(id) {
        let com = this.companies[this.companies.findIndex(a => a.id === id)]
        if (com) { return com.name } 
      },
      editItem(item) {
        this.editedItem = item
        this.editedIndex = item.id
        this.dialog = true
        if (item.user) {
          this.registred = true
          this.unregistred = false
          this.saveDisabled = false
          this.editedItem.email = this.editedItem.user.email
        } else {
          this.unregistred = true
          this.registred = false
          this.saveDisabled = false
          this.editedItem.company_id = this.companies[this.companies.findIndex(a => a.id === item.company_id)]
        }
      },
      async findUser () {
        const User = new FormData()
        User.append("email", this.editedItem.email)
        let response = await this.$http.post('/global/find_user', User)
        if (response.data.user === "not found") {
          this.unregistred = true
          this.registred = false
          this.saveDisabled = false
        } else {
          this.registred = true
          this.unregistred = false
          this.editedItem.user_id = response.data.user.id
          this.editedItem.user = response.data.user
          this.saveDisabled = false
        }
      },
      async save() {
        const Contact = new FormData()
        Contact.append("job_description", this.editedItem.job_description)
        if (this.editedItem.user) {
          Contact.append("user_id", this.editedItem.user.id)
        } else {
          Contact.append("email", this.editedItem.email)
          Contact.append("fname", this.editedItem.fname)
          Contact.append("lname", this.editedItem.lname)
          Contact.append("phone1", this.editedItem.phone1)
          if (this.editedItem.company_id instanceof Object) {
            Contact.append("company_id", this.editedItem.company_id.id)
          } else {
            Contact.append("company_name", this.editedItem.company_id)
          }
        }
        if (this.editedIndex > -1) {
          Contact.append("id", this.editedItem.id);
          let response = await this.$http.put('/site/contacts', Contact)
          this.$store.dispatch('refreshSite', this.site.id)
          this.$toast.success("Contact Updated");
        } else {
          Contact.append("site_id", this.site.id)
          let response = await this.$http.post('/site/contacts', Contact)
          this.$store.dispatch('refreshSite', this.site.id)
          this.$toast.success("Contact Created")
        }
        this.close()
      },
      close() {
        this.dialog = false
        this.registred = false
        this.unregistred = false
        this.editedItem = this.defaultItem
        this.saveDisabled = true
        this.editedIndex = -1
      }
    },
    mounted() {
      this.initialize()
      this.$store.commit('setHeaderTitle', this.site.name + ' > Contacts')
    },
  };
</script>

<style lang="scss">
  
</style>