<template>
  <v-container fluid class="tenants-page mt-5">
    <v-data-iterator
      :items="tenants"
      :search="search"
      disable-pagination
      hide-default-footer
    >
      <template v-slot:header>
        <v-toolbar flat height="90">    
          <v-icon large class="mx-4">mdi-menu</v-icon>         
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            clearable
            flat
            color="primary"
            hide-details
            prepend-inner-icon="mdi-magnify"
            label="Search"
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            dark
            class="mb-2"
            @click="dialog = true"
          >
            Add Tenant
          </v-btn>
        </v-toolbar>
        <v-dialog
          v-model="dialog"
          max-width="500px"
        >
          <v-card>
            <v-card-title>
              <span class="text-h5">{{formTitle}}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-text-field
                  v-model="editedItem.name"
                  label="Tenant name"
                ></v-text-field>
                <v-switch
                  v-model="editedItem.tenant_status"
                  inset
                  :label="editedItem.tenant_status ? 'Active' : 'Inactive'"
                ></v-switch>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="close"
              >
                Cancel
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="save"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>

      <template v-slot:default="props">
        <v-row class="mt-5">
          <v-col
            v-for="(item, index) in props.items"
            :key="index"
            cols="2"
          >
            <v-card
              class="mx-auto"
              outlined
            >
            <v-toolbar flat>    
              <v-toolbar-title>{{item.name}}</v-toolbar-title>
              <v-spacer></v-spacer>
              <toolbar-icon-button tooltip="Edit" icon="mdi-pencil" color="primary" @click="editItem(item)"/>
              <toolbar-icon-button tooltip="Select" icon="mdi-eye" color="info" @click="showItem(item)"/>
            </v-toolbar>
              <v-card-text>
                <v-btn
                  block
                  color="primary"
                  text
                  @click="toggleShowUsers(index)"
                >
                  <v-icon>mdi-account-multiple</v-icon>
                  {{userCount(item)}}
                </v-btn>

                <v-btn
                  block
                  color="primary"
                  text
                  @click="toggleShowSites(index)"
                >
                  <v-icon>mdi-office-building</v-icon>
                  {{siteCount(item)}}
                </v-btn>
              </v-card-text>

              <v-expand-transition>
                <div v-show="visibility[index].showUsers">
                  <v-divider></v-divider>

                  <v-card-text>
                    <v-list
                      nav
                      dense
                    >
                      <v-list-item-group
                        v-model="selectedUser"
                        color="primary"
                      >
                        <v-list-item
                          v-for="(user, i) in item.users"
                          :key="i"
                          three-line
                        >
                          <v-list-item-icon>
                            <v-icon class="pl-2">mdi-account</v-icon>
                          </v-list-item-icon>

                          <v-list-item-content>
                            <v-list-item-title>{{ user.username }}</v-list-item-title>
                            <v-list-item-subtitle>
                              {{ user.name }}
                            </v-list-item-subtitle>
                            <v-list-item-subtitle>
                              {{ user.id }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-card-text>
                </div>
              </v-expand-transition>
              <v-expand-transition>
                <div v-show="visibility[index].showSites">
                  <v-divider></v-divider>

                  <v-card-text>
                    <v-list>
                      <v-list-item v-for="(site, i) in item.sites" :key="i">
                        <v-list-item-icon>
                          <v-icon class="pl-2">mdi-domain</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>{{ site.name }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-card-text>
                </div>
              </v-expand-transition>
            </v-card>
          </v-col>
        </v-row>
      </template>
    </v-data-iterator>  
  </v-container>
</template>

<script>
  export default {
    name: 'Tenants',
    data: () => ({
      tenants: [],
      search: '',
      loading: true,
      dialog: false,
      editedIndex: -1,
      editedItem: {
        name: '',
        tenant_status: true
      },
      defaultItem: {
        name: '',
        tenant_status: true
      },
      showUsers: false,
      showSites: false,
      visibility: [],
      selectedUser: null
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'New Tenant' : 'Edit Tenant'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
    },

    created () {
      this.initialize()
      this.$store.commit('setHeaderTitle', 'Admin > Tenants')
      
    },

    methods: {
      async initialize () {
        let response = await this.$http.get('/admin/tenants')
        this.tenants = response.data.tenants
        this.visibility = this.tenants.map(() => ({ showUsers: false, showSites: false }))
        this.loading = false
      },

      toggleShowUsers(index) {
      this.$set(this.visibility, index, {
        ...this.visibility[index],
        showUsers: !this.visibility[index].showUsers,
        showSites: false
      })
      },
      toggleShowSites(index) {
        this.$set(this.visibility, index, {
          ...this.visibility[index],
          showSites: !this.visibility[index].showSites,
          showUsers: false
        })
      },

      userCount (tenant) {
        if (tenant.users.length == 1) {
          return tenant.users.length + " user"
        } else if (tenant.users.length > 1) {
          return tenant.users.length + " users"
        } else {
          return "no users"
        }
      },

      siteCount (tenant) {
        if (tenant.sites.length == 1) {
          return tenant.sites.length + " site"
        } else if (tenant.sites.length > 1) {
          return tenant.sites.length + " sites"
        } else {
          return "no sites"
        }
      },

      showItem(item) {
        if (this.search.length > 0 && this.search != item.name) {
          this.search = item.name
        } else if (this.search.length == 0) {
          this.search = item.name
        } else {
          this.search = ''
        }
      },

      editItem (item) {
        this.editedIndex = this.tenants.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      async save () {
        const Tenant = new FormData();
        Tenant.append("name", this.editedItem.name);
        Tenant.append("tenant_status", this.editedItem.tenant_status);
        if (this.editedIndex > -1) {
          Tenant.append("id", this.editedItem.id);
          let response = await this.$http.put('/admin/tenants', Tenant)
          Object.assign(this.tenants[this.editedIndex], response.data.tenant)
          this.$toast.success("Tenant Updated");
        } else {
          let response = await this.$http.post('/admin/tenants', Tenant)
          this.tenants.push(response.data.tenant)
          this.$toast.success("Tenant Created")
          this.visibility = this.tenants.map(() => ({ showUsers: false, showSites: false }))
        }
        this.close()
      },
    },
  }
</script>