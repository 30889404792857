const getDefaultState = () => ({
  createProductSystemInstructions: "return the detailed product specifications in json format with the following sructure\nname: basically brand and model concatenated\nproduct_category: which category of products does this product belong to\nproduct_model: what model this product is\nproduct_url: the product webpage from the manufacturer's website in english\nimage: the url of a publicly available image of the product\nproduct_status: is this product still in production or discontinued (true or false)\ndescription: a short description of the product\nfeatures: a short list of the main features of this product\nheight: the height of the product in mm\nwidth: the width of the product in mm\ndepth: the depth of the product in mm\nweight: the weight of the product in grams\npower: the power consumption of the product in W\nsku: sku of this product\nupc: upc code of this product\nean: ean code of this product\nbrand: Brand or manufacturer of this product"
})

const state = getDefaultState()

const getters = {
  createProductSystemInstructions: state => state.createProductSystemInstructions,
}

const mutations = {
  resetAiState(state) {
    Object.assign(state, getDefaultState())
  }
}

export default {
  state,
  getters,
  mutations
}