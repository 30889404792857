<template>
  <v-btn-toggle
    v-model="toggle"
  >
    <v-btn icon @click="toggleLayout(0)">
      <v-icon>mdi-table-of-contents</v-icon>
    </v-btn>
    <v-btn icon @click="toggleLayout(1)">
      <v-icon>mdi-view-grid</v-icon>
    </v-btn>
  </v-btn-toggle>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'DeviceLayoutToggle',
    data: () => ({
      layout: 'table',
      toggle: undefined,
    }),
    computed: {
      ...mapGetters(['site', 'devicesLayout']),
    },
    mounted () {
      this.layout = this.devicesLayout
      this.toggle = this.layout === 'table' ? 0 : 1
    },
    methods: {
      toggleLayout(index) {
        this.$store.commit('setDevicesLayout', index)
      },
    }
  }
</script>