<template>
  <v-switch
    v-model="filtered.show_archived"
    @change="toggleArchive"
    inset
    label="Show Archived Devices"
    class="ml-4"
  ></v-switch>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'DeviceArchiveToggle',
    data: () => ({
      filtered: [],
      active: false
    }),
    computed: {
      ...mapGetters(['site', 'sitePermission', 'deviceFilterVisible', 'deviceFilters']),
    },
    mounted () {
      this.filtered = Object.assign({}, this.deviceFilters)
    },
    methods: {
      toggleArchive() {
        this.filtered = Object.assign({}, this.deviceFilters)
        this.filtered.show_archived = !this.filtered.show_archived
        this.$store.commit('setDeviceFilters', this.filtered)
        this.$store.commit('saveDeviceFilters')
        this.$nextTick(() => {
          this.filtered = Object.assign({}, this.deviceFilters)
        })
      },
    }
  }
</script>